/* eslint-disable camelcase */
import { fromJS, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';
import { dynamicSagas as extraReducers } from '../utils/dynamicHelpers';
import alert from './alert';
import alertArticlePage from './alertArticlePage';
import alerts from './alerts';
import appHistory from './appHistory';
import categories from './categories';
import catSalesAndMarketing from './catSalesAndMarketing';
import catSystemAndTech from './catSystemAndTech';
import dateRange from './dateRange';
import dynamic from './dynamic';
import error from './error';
import { favoritePagesReducer } from './favoritePages';
import featuredFieldManagementNews from './featuredFieldManagementNews';
import featuredProductUpdates from './featuredProductUpdates';
import featuredSales from './featuredSales';
import field_managements from './field_managements';
import fieldManagement from './fieldManagement';
import fieldManagementNewsSelections from './fieldManagementNewsSelections';
import findGuideLines from './findGuideLines';
import guide from './guide';
import guides from './guides';
import guidesByState from './guidesByState';
import guidesSelections from './guidesSelections';
import jobAidsSelections from './guidesSelections';
import home_articles from './home_articles';
import job_aids from './job_aids';
import monthlyJobAids from './jobAids/monthlyJobAids';
import prelaunchJobAids from './jobAids/prelaunchJobAids';
import bottomMenu from './menus/bottomMenu';
import mobileMenu from './menus/mobileMenu';
import topMenu from './menus/topMenu';
import product from './product';
import product_updates from './product_updates';
import allProducts from './products/allProducts';
import dropdownProducts from './products/dropdownProducts';
import products from './products/products';
import productUpdatesSelectedCat from './productUpdatesSelectedCat';
import queryTimeStamps from './queryTimeStamps';
import redirect_records from './redirect_records';
import rsm from './rsm';
import rsmSearchResults from './rsmSearchResults';
import sales from './sales';
import sales_promotion from './sales_promotion';
import sales_promotions from './sales_promotions';
import salesSelectedCat from './salesSelectedCat';
import sandwichMenu from './sandwichMenu';
import savedMegaQuery from './savedMegaQuery';
import search from './search';
import selected from './selected';
import simplePage from './simplePage';
import simplePages from './simplePages';
import states from './states';
import staticCohorts from './staticCohorts';
import staticFooter from './staticFooter';
import staticHome from './staticHome';
import statics from './statics';
import topMenuClicked from './topMenuClicked';
import training from './training';
import trainingPageLoaded from './trainingPageLoaded';
import trainings from './trainings';
import trainingsAllSystems from './trainingsAllSystem';
import trainingsAllTypes from './trainingsAllTypes';
import trainingsFilter from './trainingsFilter';
import unchecked_by_category_count from './unchecked_by_category_count';
import unchecked_count from './unchecked_count';
import userInfo from './userInfo';
import webinars from './webinars';
import webinarsFilter from './webinarsFilter';

export const types = {
    ...utils.requestTypes('APP'),
    APP_RESET: 'APP_RESET',
    APP_INIT: 'APP_INIT',
};

export const actions = {
    appRequest: (obj) => utils.action(types.APP_REQUEST, obj),
    appSuccess: (response) => utils.action(types.APP_SUCCESS, { response }),
    appFailure: (error) => utils.action(types.APP_FAILURE, { error }),
    appReset: (obj) => utils.action(types.APP_RESET, obj),
    appInit: (obj) => utils.action(types.APP_INIT, obj),
};

const initialState = Map();

function status(state = initialState, action) {
    switch (action.type) {
    case types.APP_REQUEST:
        if (action.fetch && state.has(action.fetch)) {
            return state.update(action.fetch, (s) => {
                return s.set('request', fromJS(action.payload))
                    .set('loading', true)
                    .delete('success')
                    .delete('error');
            });
        }

        if (action.fetch && !state.has(action.fetch)) {
            return state.set(action.fetch, fromJS({
                request: action.payload,
                loading: true,
            }));
        }

        return state;

    case types.APP_SUCCESS:
        if (action.fetch) {
            return state.update(action.fetch, (s) => s.set('success', fromJS(action.payload)).set('loading', false));
        }

        return state;

    case types.APP_FAILURE:
        if (action.fetch) {
            return state.update(action.fetch, (s) => s.set('error', fromJS(action.payload)).set('loading', false));
        }

        return state;

    case types.APP_RESET:
        if (state.has(action.fetch)) {
            return state.set(action.fetch, fromJS({
                request: null,
                loading: false,
            }));
        }

        return state;

    default:
        return state;
    }
}

/*
	A Note of using combine instead of combineReducers

	Combine():
	combine the reducers with an Object of dynamicReducers,
	should be exported to a helper that can list off all the requests
*/
const combine = () => {
    const dynamicReducers = {};
    for (let i = 0; i < extraReducers.length; i++) {
        dynamicReducers[extraReducers[i]] = dynamic(extraReducers[i]);
    }

    return combineReducers({
        status,
        alert,
        alerts,
        allProducts,
        bottomMenu,
        categories,
        catSystemAndTech,
        catSalesAndMarketing,
        dateRange,
        dropdownProducts,
        favoritePages: favoritePagesReducer,
        featuredFieldManagementNews,
        featuredProductUpdates,
        featuredSales,
        fieldManagement,
        field_managements,
        fieldManagementNewsSelections,
        guide,
        guides,
        guidesByState,
        guidesSelections,
        job_aids,
        jobAidsSelections,
        mobileMenu,
        monthlyJobAids,
        prelaunchJobAids,
        product,
        products,
        home_articles,
        product_updates,
        productUpdatesSelectedCat,
        rsm,
        savedMegaQuery,
        sales,
        salesSelectedCat,
        sales_promotion,
        sales_promotions,
        search,
        simplePage,
        simplePages,
        states,
        staticCohorts,
        staticFooter,
        staticHome,
        statics,
        topMenu,
        training,
        trainings,
        queryTimeStamps,
        webinars,
        redirect_records,
        unchecked_by_category_count,
        unchecked_count,
        selected,

        appHistory,
        findGuideLines,
        sandwichMenu,
        topMenuClicked,
        trainingsAllSystems,
        trainingsAllTypes,
        trainingsFilter,
        webinarsFilter,
        trainingPageLoaded,
        alertArticlePage,
        rsmSearchResults,
        userInfo,
        error,
        ...dynamicReducers,
    });
};

export default combine();

const getStatus = (state) => state.get('app').get('status');

export const selectors = {
    getStatus: createSelector([getStatus], (s) => s),
};
