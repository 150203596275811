import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('WEBINARS'),
    WEBINARS_SET: 'WEBINARS_SET',
    WEBINARS_GET: 'WEBINARS_GET',
    WEBINARS_SUCCESS: 'WEBINARS_SUCCESS',
};

export const actions = {
    webinarsSet: (obj) => utils.action(types.WEBINARS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    webinars: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.WEBINARS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.WEBINARS_SET:
        if (action.payload.webinars) {
            if (action.payload.webinars.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    webinars: action.payload.webinars,
                    page: action.payload.page,
                    total: action.payload.total,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            webinars: [],
            page: 0,
            total: 0,
        });
    case types.WEBINARS_RESET:
        return initialState;
    default:
        return state;
    }
};

const getWebinars = (state) => state.get('app').get('webinars');

export const selectors = {
    getWebinars: createSelector([getWebinars], (webinars) => webinars),
};
