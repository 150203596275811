import { fromJS } from 'immutable';

import * as utils from '../utils/duckHelpers';

const staticHomeTypes = {
    ...utils.requestTypes('STATIC_HOME'),
    STATIC_HOME_GET: 'STATIC_HOME_GET',
    STATIC_HOME_SET: 'STATIC_HOME_SET',
    STATIC_HOME_RESET: 'STATIC_HOME_RESET',
    STATIC_HOME_SUCCESS: 'STATIC_HOME_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
    data: null,
});

const staticHomeReducer = (state = initialState, action) => {
    switch (action.type) {
    case staticHomeTypes.STATIC_HOME_GET:
        return state.merge({
            loaded: 'loading',
        });
    case staticHomeTypes.STATIC_HOME_SET:
        return state.merge({
            loaded: 'complete',
            data: action.payload.static,
        });
    case staticHomeTypes.STATIC_HOME_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    staticHomeReducer as default,
    staticHomeTypes,
};
