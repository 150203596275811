import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const types = utils.requestTypes('GUIDE');

const initialState = fromJS({
    loaded: status.LOADING,
    guide: {},
    erro: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.GUIDE_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.GUIDE_SET:
        return state.merge({
            loaded: status.COMPLETE,
            guide: action.payload.guide,
            error: null,
        });
    case types.GUIDE_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            guide: {},
            error: action.error,
        });
    case types.GUIDE_RESET:
        return initialState;
    default:
        return state;
    }
};

const getGuide = (state) => state.getIn(['app', 'guide']);

export const selectors = {
    getGuide: createSelector(identity, getGuide),
};
