import axios from 'axios';

import basePath from '@src/services/setRoute';
import { currentJwt, refreshJwt } from '@src/utils/jwtHelpers';
import { stringifySearch } from '@src/utils/urlHelpers';

const buildApiURL = (path, search) => {
    let url = basePath + path;

    if (search) {
        if (typeof search === 'string') {
            if (search[0] === '?') {
                url += search;
            } else {
                url += '?' + search;
            }
        } else {
            url += stringifySearch(search, { addQueryPrefix: true });
        }
    }

    return url;
};

const apiGet = (url) => {
    const token = currentJwt();
    return axios
        .get(
            url,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Total, Page',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            },
        )
        .then((res) => {
            let _total = res.headers.total;
            let _page = res.headers.page;
            let _perPage = res.headers['per-page'];

            // We need to renew jwt token every api request
            const newJwt = res.headers.jwt;
            if (newJwt) {
                refreshJwt(newJwt);
            }

            if (_total) {
                _total = _total ? parseInt(_total, 10) : 0;
                res.data.total = _total;
            }

            if (_page) {
                _page = _page ? parseInt(_page, 10) : 0;
                res.data.page = _page;
            }

            if (_perPage) {
                _perPage = _perPage ? parseInt(_perPage, 10) : 0;
                res.data.perPage = _perPage;
            }

            return res.data;
        });
};

/**
 * Send a POST request
 * @param {string} url - full URL for request
 * @param {object} data - data to send
 * @returns - axios promise
 */
const apiPost = (url, data) => {
    const token = currentJwt();
    return axios({
        method: 'post',
        url,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });
};

/**
 * Send a PUT request
 * @param {string} url - full URL for request
 * @param {object} data - data to send
 * @returns - axios promise
 */
const apiPut = (url, data) => {
    const token = currentJwt();
    return axios({
        method: 'put',
        url,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });
};

/**
 * Send a PATCH request
 * @param {string} url - full URL for request
 * @param {object} data - data to send
 * @returns - axios promise
 */
const apiPatch = (url, data) => {
    const token = currentJwt();
    return axios({
        method: 'patch',
        url,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });
};

/**
 * Send a DELETE request
 * @param {string} url - full URL for request
 * @returns - axios promise
 */
const apiDelete = (url) => {
    const token = currentJwt();
    return axios({
        method: 'delete',
        url,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });
};

export {
    apiDelete,
    apiGet,
    apiPatch,
    apiPost,
    apiPut,
    buildApiURL,
};
