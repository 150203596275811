
export const resetRedirectRecords = (fetch) => ({
    payload: {
        dataset: 'redirect_records',
        action: 'reset',
        route: '',
        data: {},
    },
    fetch,
});

export default resetRedirectRecords;
