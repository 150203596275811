import { all, put, takeLatest } from 'redux-saga/effects';

import { types as staticsTypes } from '../ducks/statics';

export function * watchStatics() {
    yield takeLatest(staticsTypes.STATICS_RESET, onStaticsReset);
    yield takeLatest(staticsTypes.STATICS_GET, onStaticsGet);
    yield takeLatest(staticsTypes.STATICS_SUCCESS, onStaticsGetResponse);
}

export function * onStaticsReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onStaticsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onStaticsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'statics') {
        return yield all([
            put({
                type: staticsTypes.STATICS_SET,
                payload: response,
            }),
        ]);
    }
}
