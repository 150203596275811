import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TRAININGSALLTYPES'),
    TRAININGSALLTYPES_GET: 'TRAININGSALLTYPES_GET',
    TRAININGSALLTYPES_SET: 'TRAININGSALLTYPES_SET',
    TRAININGSALLTYPES_SUCCESS: 'TRAININGSALLTYPES_SUCCESS',
};

export const actions = {
    trainingsAllTypesSet: (obj) => utils.action(types.TRAININGSALLTYPES_SET, obj),
};

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAININGSALLTYPES_SET:
        return action.payload.data;

    case types.TRAININGSALLTYPES_GET:
        return state;

    default:
        return state;
    }
};

export const getTrainingsAllTypes = (state) => state.get('app').get('trainingsAllTypes');
