import { Taxonomies } from '@src/global/taxonomies';
import SetRoute from '@src/services/setRoute';

export const fetchCatSalesAndMarketing = (fetch, query = '') => {
    return ({
        payload: {
            dataset: 'catSalesAndMarketing',
            action: 'get',
            route: SetRoute + `/api/v1/taxonomies/${Taxonomies.salesAndMarketing}/categories${query}`,
            data: {},
        },
        fetch,
    });
};

export default fetchCatSalesAndMarketing;
