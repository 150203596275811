import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    PRODUCT_UPDATES_SELECT_CAT: 'PRODUCT_UPDATES_SELECT_CAT',
};

export const actions = {
    setProductUpdatesSelectedCat: (obj) => utils.action(types.PRODUCT_UPDATES_SELECT_CAT, obj),
};

const initialState = fromJS({
    data: {
        currentPage: 1,
        dropdownData: [],
        dropdownDataLoaded: false,
        loaded: false,
        searchString: '',
        selectedCategory: 'all-options',
        selectedType: '',
    },
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.PRODUCT_UPDATES_SELECT_CAT:
        if (action.payload) {
            return fromJS({
                data: action.payload,
            });
        }

        return state;
    default:
        return state;
    }
};

const getProductUpdatesSelectedCat = (state) => state.get('app').get('productUpdatesSelectedCat');

export const selectors = {
    getProductUpdatesSelectedCat: createSelector([getProductUpdatesSelectedCat], (data) => data),
};
