import { all, put, takeLatest } from 'redux-saga/effects';

import { types as fieldManagementsTypes } from '../ducks/field_managements';

export function * watchFieldManagements() {
    yield takeLatest(fieldManagementsTypes.FIELD_MANAGEMENTS_GET, onFieldManagementsGet);
    yield takeLatest(fieldManagementsTypes.FIELD_MANAGEMENTS_SUCCESS, onFieldManagementsGetResponse);
}

function * onFieldManagementsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onFieldManagementsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'field_managements') {
        return yield all([
            put({
                type: fieldManagementsTypes.FIELD_MANAGEMENTS_SET,
                payload: response,
            }),
        ]);
    }
}
