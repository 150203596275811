import { all, put, takeLatest } from 'redux-saga/effects';

import { types as homeArticlesTypes } from '../ducks/home_articles';

export function * watchHomeArticles() {
    yield takeLatest(homeArticlesTypes.HOME_ARTICLES_GET, onHomeArticlesGet);
    yield takeLatest(homeArticlesTypes.HOME_ARTICLES_SUCCESS, onHomeArticlesGetResponse);
}

function * onHomeArticlesGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onHomeArticlesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'home_articles') {
        return yield all([
            put({
                type: homeArticlesTypes.HOME_ARTICLES_SET,
                payload: response,
            }),
        ]);
    }
}
