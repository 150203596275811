import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

const initialState = fromJS([]);

import * as utils from '../utils/duckHelpers';
import { dynTypes } from '../utils/dynamicHelpers';

/*
	DynamicReduer:
	return a reducer function that can be branded
	with whatever you want it to be renamed
*/

export const types = (duck) => (
    dynTypes(duck.name, 'SET')
);

export const actions = {
    dynamicSet: (obj) => utils.action(types(obj), obj),
};

const dynamicReducer = (renamed) => (state = initialState, action) => {
    switch (action.type) {
    case `${renamed.toUpperCase()}_SET`:
        return state.update((s) => {
            let newDynamic = fromJS(action.payload);
            newDynamic = newDynamic.filter((n) => {
                return (!s.find((c) => {
                    if (typeof c === 'number' || typeof n === 'number') {
                        return true;
                    }

                    return (c.get('id') === n.get('id'));
                }));
            });
            return s.concat(newDynamic);
        });

    case `${renamed.toUpperCase()}_UPDATE`:
        return state.update((s) => {
            const node = s.find((l) => l.get('_id') === action.payload.get('_id'));
            if (!node) {
                return s;
            }

            return s.set(s.indexOf(node), fromJS(action.payload));
        });

    case `${renamed.toUpperCase()}_RESET`:
        return initialState;

    default:
        return state;
    }
};

export default dynamicReducer;

const getDynamic = (state, fetch) => {
    return state.get('app').get(fetch);
};

export const selectors = {
    getDynamic: createSelector([getDynamic], (dynamicReturn) => dynamicReturn),
};
