import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SELECTED'),
    SELECTED_SET: 'SELECTED_SET',
    SELECTED_GET: 'SELECTED_GET',
    SELECTED_SUCCESS: 'SELECTED_SUCCESS',
};

export const actions = {
    selectedSet: (obj) => utils.action(types.SELECTED_SET, obj),
};

const initialState = fromJS({});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SELECTED_SET:
        if (action.payload.data) {
            return fromJS(action.payload.data);
        }

        return fromJS({});

    case types.SELECTED_RESET:
        return initialState;

    default:
        return state;
    }
};

const getSelected = (state) => state.get('app').get('selected');

export const selectors = {
    getSelected: createSelector([getSelected], (selected) => selected),
};
