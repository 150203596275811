import { all, put, takeLatest } from 'redux-saga/effects';

import { allProductsTypes } from '@src/ducks/products/allProducts';
import { dropdownProductsTypes } from '@src/ducks/products/dropdownProducts';
import { types as productsTypes } from '@src/ducks/products/products';

export function * watchProducts() {
    yield takeLatest(productsTypes.PRODUCTS_GET, onProductsGet);
    yield takeLatest(productsTypes.PRODUCTS_SUCCESS, onProductsGetResponse);

    yield takeLatest(allProductsTypes.ALL_PRODUCTS_GET, onProductsGet);
    yield takeLatest(allProductsTypes.ALL_PRODUCTS_SUCCESS, onAllProductsGetResponse);

    yield takeLatest(dropdownProductsTypes.DROPDOWN_PRODUCTS_GET, onProductsGet);
    yield takeLatest(dropdownProductsTypes.DROPDOWN_PRODUCTS_SUCCESS, onDropdownProductsGetResponse);
}

function * onProductsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onProductsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'products') {
        return yield all([
            put({
                type: productsTypes.PRODUCTS_SET,
                payload: response,
            }),
        ]);
    }
}

function * onAllProductsGetResponse({ response, payload }) {
    if (payload.action !== 'get' || !response || payload.dataset !== 'all_products') {
        return;
    }

    yield put({
        type: allProductsTypes.ALL_PRODUCTS_SET,
        payload: response,
    });
}

function * onDropdownProductsGetResponse({ response, payload }) {
    if (payload.action !== 'get' || !response || payload.dataset !== 'dropdown_products') {
        return;
    }

    yield put({
        type: dropdownProductsTypes.DROPDOWN_PRODUCTS_SET,
        payload: response,
    });
}
