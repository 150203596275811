import { all, put, takeLatest } from 'redux-saga/effects';

import { types as trainingTypes } from '../ducks/training';

export function * watchTraining() {
    yield takeLatest(trainingTypes.TRAINING_GET, onTrainingGet);
    yield takeLatest(trainingTypes.TRAINING_SUCCESS, onTrainingGetResponse);
}

export function * onTrainingGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTrainingGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'training') {
        return yield all([
            put({
                type: trainingTypes.TRAINING_SET,
                payload: response,
            }),
        ]);
    }
}
