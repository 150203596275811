import React from 'react';

import parse, { domToReact } from 'html-react-parser';

/**
 * @function parseStyles
 * Parses a string of inline styles into a javascript object with casing for react
 *
 * @param {string} styles
 * @returns {Object}
 */
function parseStyles(styles) {
    if (!styles) {
        return {};
    }

    function handleMapKeys(keyString) {
        return keyString.replace(/-./g, (c) => c.substr(1).toUpperCase());
    }

    function handleMapValues(valueString) {
        if (valueString.indexOf(':') === -1) {
            return valueString;
        }

        // This handles value e.g. 'url("https://example.com/image.png")'
        return valueString.split(':').slice(1).join(':');
    }

    function mapper(style) {
        const keyValue = style.split(':');
        const key = keyValue[0].trim();
        const value = keyValue[1].trim();
        return [
            handleMapKeys(key),
            handleMapValues(value),
        ];
    }

    function reducer(styleObj, style) {
        return {
            ...styleObj,
            [style[0]]: style[1],
        };
    }

    function validStyles(pairString) {
        return pairString.filter((style) => {
            return style.split(':').length === 2;
        });
    }

    const splitStyles = validStyles(styles.split(';'));

    return splitStyles.map(mapper).reduce(reducer, {});
}

function domNodeReplacement(domNode) {
    if (!domNode.attribs) {
        return domToReact(domNode);
    }

    const hasDataClass = Object.prototype.hasOwnProperty.call(domNode.attribs, 'data-class');
    const hasFroalaOriginalClass = Object.prototype.hasOwnProperty.call(domNode.attribs, 'fr-original-class');
    const hasFroalaOriginalStyle = Object.prototype.hasOwnProperty.call(domNode.attribs, 'fr-original-style');

    const dataClass = hasDataClass ? domNode.attribs['data-class'] : '';
    const froalaOriginalClass = hasFroalaOriginalClass ? domNode.attribs['fr-original-class'] : '';
    const froalaOriginalStyle = hasFroalaOriginalStyle ? domNode.attribs['fr-original-style'] : '';

    const isDiv = Boolean(domNode.name === 'div');

    if (hasFroalaOriginalStyle && domNode.name === 'img') {
        return <img className="froala-content" src={domNode.attribs.src} style={parseStyles(domNode.attribs.style)} />;
    }

    if (dataClass === 'col' && isDiv) {
        return (
            <div className="col-xs-1 col-md" data-class={dataClass} fr-original-class={froalaOriginalClass} fr-original-style={froalaOriginalStyle}>
                {domToReact(domNode.children)}
            </div>
        );
    }

    if (dataClass === 'col-3' && isDiv) {
        return (
            <div className="col-xs-1 col-md-3" data-class={dataClass} fr-original-class={froalaOriginalClass} fr-original-style={froalaOriginalStyle}>
                {domToReact(domNode.children)}
            </div>
        );
    }

    if (dataClass === 'col-9' && isDiv) {
        return (
            <div className="col-xs-1 col-md-9" data-class={dataClass} fr-original-class={froalaOriginalClass} fr-original-style={froalaOriginalStyle}>
                {domToReact(domNode.children)}
            </div>
        );
    }

    if (domNode.attribs['data-f-id'] === 'pbf') {
        // Must return a node; Will not replace domNode with `null` or `''`.
        return <div />;
    }
}

export const SafelyRenderHtml = (props) => {
    if (!props) return null;

    const htmlString = props.replace(/contenteditable/g, 'data-unusable-field');
    const options = {
        trim: true,
        replace: (domNode) => {
            return domNodeReplacement(domNode);
        },
    };

    return parse(htmlString, options);
};

export default SafelyRenderHtml;
