import { all, put, takeLatest } from 'redux-saga/effects';

import { types as selectedTypes } from '../ducks/selected';

export function * watchSelected() {
    yield takeLatest(selectedTypes.SELECTED_GET, onSelectedGet);
    yield takeLatest(selectedTypes.SELECTED_SUCCESS, onSelectedGetResponse);
}

export function * onSelectedGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onSelectedGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'selected') {
        return yield all([
            put({
                type: selectedTypes.SELECTED_SET,
                payload,
            }),
        ]);
    }
}
