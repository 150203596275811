import { all, put, takeLatest } from 'redux-saga/effects';

import { types as trainingsAllSystemTypes } from '../ducks/trainingsAllSystem';

export function * watchTrainingsAllSystem() {
    yield takeLatest(trainingsAllSystemTypes.TRAININGSALLSYSTEMS_GET, onTrainingsAllSystemGet);
    yield takeLatest(trainingsAllSystemTypes.TRAININGSALLSYSTEMS_SUCCESS, onTrainingsAllSystemGetResponse);
}

export function * onTrainingsAllSystemGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTrainingsAllSystemGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'trainingsAllSystems') {
        return yield all([
            put({
                type: trainingsAllSystemTypes.TRAININGSALLSYSTEMS_SET,
                payload: response,
            }),
        ]);
    }
}
