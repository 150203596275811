import SetRoute from '../services/setRoute';

export const fetchStatics = (fetch, param = '') => (
    {
        payload: {
            dataset: 'statics',
            action: 'get',
            route: SetRoute + `/api/v1/statics${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchStatics;
