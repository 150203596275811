import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import {
    fixEditable,
} from '../services';
import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SALES_PROMOTION'),
    SALES_PROMOTION_GET: 'SALES_PROMOTION_GET',
    SALES_PROMOTION_SET: 'SALES_PROMOTION_SET',
    SALES_PROMOTION_SUCCESS: 'SALES_PROMOTION_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    sales_promotionSet: (obj) => utils.action(types.SALES_PROMOTION_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SALES_PROMOTION_SET:
        return state;

    case types.SALES_PROMOTION_GET:
        if (action.payload.sales_promotion) {
            return fromJS({
                // eslint-disable-next-line camelcase
                sales_promotion: fixEditable(action.payload.sales_promotion),
                loaded: 'complete',
            });
        }

        return fromJS({
            loaded: 'empty',
        });

    default:
        return state;
    }
};

// eslint-disable-next-line camelcase
export const getSales_promotion = (state) => state.get('app').get('sales_promotion');

export const selectors = {
    // eslint-disable-next-line camelcase
    getSales_promotion: createSelector([getSales_promotion], (sales_promotion) => sales_promotion),
};
