import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '@src/utils/duckHelpers';

export const types = {
    ...utils.requestTypes('PRODUCTS'),
    PRODUCTS_SET: 'PRODUCTS_SET',
    PRODUCTS_GET: 'PRODUCTS_GET',
    PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
};

export const actions = {
    productsSet: (obj) => utils.action(types.PRODUCTS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.PRODUCTS_SET:
        if (action.payload.products) {
            if (action.payload.products.length > 0) {
                return fromJS({
                    products: action.payload.products,
                    loaded: 'complete',
                });
            }
        }

        return fromJS({
            products: [],
            loaded: 'empty',
        });

    case types.PRODUCTS_RESET:
        return initialState;

    default:
        return state;
    }
};

const getProducts = (state) => state.get('app').get('products');

export const selectors = {
    getProducts: createSelector([getProducts], (products) => products),
};
