import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ForceAppScrollTopToZero } from '@src/services';

import ContactUsLink from '@src/components/ContactUsLink';

import bootstrap from '@src/css/bootstrap.scss';
import ml from '@src/css/metlife_css/buttons.scss';

import styles from './error.scss';

class Error500 extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        ForceAppScrollTopToZero();
    }

    handleBackClick = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        return (
            <div id="home" className={`col-12 ${styles.error_page}`}>
                <div className={bootstrap.container}>
                    <div className={bootstrap.row}>
                        <div className={`${bootstrap.col} ${bootstrap['text-center']}`}>
                            <div className="error-container">
                                <img src="/img/icons/stop-watch-icon.svg" alt="A Stop watch " />
                                <h1>We&#39;re Sorry</h1>
                                <h2>The system has timed out due to a technical error.</h2>
                                <p><a className={ml['ml-link']} onClick={this.handleBackClick}>Go back to a previous page </a> or <ContactUsLink>contact us</ContactUsLink> for help.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Error500);
