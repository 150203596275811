import { Map, fromJS } from 'immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import Ducks from './ducks/root';
import Sagas from './sagas/root';

const store = () => {
    const initialState = fromJS(window.INITIAL_STATE) || Map();
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];
    let composeEnhancers = compose;

    /**
    * When 'production' is application will not not run the
    * REACT DEVTOOLS and produce any react based bad practices
    * or other react errors that are otherwise visible.
    */
    if (
        process.env.NODE_ENV === 'production'
            && window.__REACT_DEVTOOLS_GLOBAL_HOOK__
            && Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers).length
    ) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {};
    }

    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    const DataStore = createStore(
        Ducks,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    sagaMiddleware.run(Sagas);

    return DataStore;
};

export default store;
