import * as utils from '@src/utils/duckHelpers';

export const types = {
    ...utils.requestTypes('ERROR'),
    ERROR_403: 'ERROR_403',
    ERROR_401: 'ERROR_401',
    ERROR_500: 'ERROR_500',
    ERROR_LOADED: 'ERROR_LOADED',
};

export const actions = {
    handle403Error: (obj) => utils.action(types.ERROR_403, obj),
};

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.ERROR_403:
        return {
            error: 403,
            errorPage: '/authorization-error',
        };

    case types.ERROR_401:
        return {
            error: 401,
            errorPage: '/inactivity-error',
        };

    case types.ERROR_500:
        return {
            error: 500,
            errorPage: '/server-down-error',
        };

    case types.ERROR_LOADED:
        return {
            errorLoaded: true,
        };

    default:
        return state;
    }
};

export const getError = (state) => state.getIn(['app', 'error']);
