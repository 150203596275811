import SetRoute from '../services/setRoute';

export const fetchSearch = (fetch, search) => ({
    payload: {
        dataset: 'search',
        action: 'get',
        route: SetRoute + `/api/v1/search${search}`,
        data: {},
    },
    fetch,
});
