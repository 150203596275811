import SetRoute from '../services/setRoute';

export const fetchRedirectRecords = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'redirect_records',
            action: 'get',
            route: SetRoute + `/api/v1/redirect_records/check${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchRedirectRecords;
