import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('FINDGUIDELINES'),
    FINDGUIDELINES_SET: 'FINDGUIDELINES_SET',
    FINDGUIDELINES_GET: 'FINDGUIDELINES_GET',
    FINDGUIDELINES_RESET: 'FINDGUIDELINES_RESET',
    FINDGUIDELINES_SUCCESS: 'FINDGUIDELINES_SUCCESS',
};

export const actions = {
    findGuideLinesSet: (obj) => utils.action(types.FINDGUIDELINES_SET, obj),
};

const initialState = fromJS([
    {
        selectResource: null,
        selectState: null,
        selectProduct: null,
        productPreset: 0,
        selectionNumber: 0,
    },
]);

const calculateSelectionNumber = (payload) => {
    let selectionNumber = 0;
    if (payload.selectResource) {
        selectionNumber++;
    }

    if (payload.selectState) {
        selectionNumber++;
    }

    if (payload.selectProduct) {
        selectionNumber++;
    }

    return selectionNumber;
};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FINDGUIDELINES_SET:
        return fromJS([
            {
                selectResource: action.payload.data.selectResource,
                selectState: action.payload.data.selectState,
                selectProduct: action.payload.data.selectProduct,
                productPreset: action.payload.data.productPreset ? action.payload.data.productPreset : 0,
                selectionNumber: calculateSelectionNumber(action.payload.data),
            },
        ]);
    case types.FINDGUIDELINES_RESET:
        return initialState;
    default:
        return state;
    }
};

const getFindGuideLines = (state) => state.get('app').get('findGuideLines');

export const selectors = {
    getFindGuideLines: createSelector([getFindGuideLines], (findGuideLines) => findGuideLines),
};

