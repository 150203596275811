export const setSandwichMenu = (clicked) => ({
    payload: {
        dataset: 'sandwichMenu',
        action: 'set',
        data: clicked,
        route: '',
    },
});

export default setSandwichMenu;
