import { all, put, takeLatest } from 'redux-saga/effects';

import { types as webinarsTypes } from '../ducks/webinars';

export function * watchWebinars() {
    yield takeLatest(webinarsTypes.WEBINARS_GET, onWebinarsGet);
    yield takeLatest(webinarsTypes.WEBINARS_SUCCESS, onWebinarsGetResponse);
}

export function * onWebinarsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onWebinarsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'webinars') {
        return yield all([
            put({
                type: webinarsTypes.WEBINARS_SET,
                payload: response,
            }),
        ]);
    }
}
