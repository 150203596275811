import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { favoritePagesActions, favoritePagesTypes } from '@src/ducks/favoritePages';
import { apiDelete, apiGet, apiPatch, apiPost, buildApiURL } from '@src/services/api';
import { handleSagaFailure } from '@src/utils/sagaHelpers';

export function * watchFavoritePages() {
    yield takeLatest(favoritePagesTypes.FAVORITE_PAGES_REQUEST, onRequest);
    yield takeEvery(favoritePagesTypes.FAVORITE_PAGES_CREATE, onCreate);
    yield takeEvery(favoritePagesTypes.FAVORITE_PAGES_REMOVE, onRemove);
    yield takeEvery(favoritePagesTypes.FAVORITE_PAGES_UPDATE_POSITION, onUpdatePosition);
}

function * onRequest({ payload }) {
    try {
        const url = buildApiURL('/api/v1/favorite_pages', payload);
        const response = yield call(apiGet, url);

        yield put(favoritePagesActions.success(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(favoritePagesActions.failure(error));
    }
}

function * onCreate({ payload }) {
    try {
        const response = yield call(apiPost, buildApiURL('/api/v1/favorite_pages'), payload);

        yield put(favoritePagesActions.createSuccess(response));
        yield put(favoritePagesActions.request());
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(favoritePagesActions.createFailure(error));
    }
}

function * onRemove({ payload }) {
    try {
        const response = yield call(apiDelete, buildApiURL('/api/v1/favorite_pages/' + payload.id));

        yield put(favoritePagesActions.removeSuccess(response));
        yield put(favoritePagesActions.request());
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(favoritePagesActions.removeFailure(error));
    }
}

function * onUpdatePosition({ payload }) {
    try {
        const response = yield call(
            apiPatch,
            buildApiURL(`/api/v1/favorite_pages/${payload.id}/position`),
            { position: payload.position + 1 },
        );

        yield put(favoritePagesActions.updatePositionSuccess(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(favoritePagesActions.updatePositionFailure(error));
        yield put(favoritePagesActions.request());
    }
}
