import { fromJS } from 'immutable';
import jwtDecode from 'jwt-decode';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import { currentJwt } from '@src/utils/jwtHelpers';

export const types = {
    USER_INFO_SET: 'USER_INFO_SET',
};

const initialState = fromJS({
    agentCode: null,
    accessLevel: null,
    premierAccess: null,
    salesChannel: null,
    exp: null,
    permissions: null,
});

const userInfo = (state = initialState, action) => {
    switch (action.type) {
    case types.USER_INFO_SET: {
        const info = {
            agentCode: null,
            accessLevel: null,
            premierAccess: null,
            salesChannel: null,
            exp: null,
            permissions: null,
        };
        const decodedJWT = jwtDecode(currentJwt());
        if (decodedJWT) {
            info.agentCode = decodedJWT.agent_code;
            info.accessLevel = decodedJWT.access_level;
            info.premierAccess = decodedJWT.premier_access;
            info.salesChannel = decodedJWT.sales_channel;
            info.exp = decodedJWT.exp;
            info.permissions = decodedJWT.permissions;
        }

        return state.merge(info);
    }

    default:
        return state;
    }
};

const getUserInfo = createSelector(identity, (state) => state.getIn(['app', 'userInfo']));
const getAccessLevel = createSelector(getUserInfo, (userInfo) => userInfo.get('accessLevel'));
const getSalesChannel = createSelector(getUserInfo, (userInfo) => userInfo.get('salesChannel'));
const getExp = createSelector(getUserInfo, (userInfo) => userInfo.get('exp'));
const getPermissions = createSelector(getUserInfo, (userInfo) => userInfo.get('permissions'));

const selectors = {
    getUserInfo,
    getAccessLevel,
    getSalesChannel,
    getExp,
    getPermissions,
};

export { userInfo as default, selectors };
