/* eslint-disable */

// Based on https://stackoverflow.com/a/9851769

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

export {
    isIE,
};
