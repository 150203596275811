import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TRAININGS'),
    TRAININGS_SET: 'TRAININGS_SET',
    TRAININGS_GET: 'TRAININGS_GET',
    TRAININGS_SUCCESS: 'TRAININGS_SUCCESS',
};

export const actions = {
    trainingsSet: (obj) => utils.action(types.TRAININGS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    trainings: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAININGS_GET:
        return state.merge({
            ...state,
            loaded: 'loading',
        });
    case types.TRAININGS_SET:
        if (action.payload.trainings) {
            if (action.payload.trainings.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    trainings: action.payload.trainings,
                    page: action.payload.page,
                    total: action.payload.total,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            trainings: [],
            page: 0,
            total: 0,
        });
    case types.TRAININGS_RESET:
        return initialState;
    default:
        return state;
    }
};

const getTrainings = (state) => state.get('app').get('trainings');

export const selectors = {
    getTrainings: createSelector([getTrainings], (trainings) => trainings),
};
