import { all, put, takeLatest } from 'redux-saga/effects';

import { types as webinarsFilterTypes } from '../ducks/webinarsFilter';

export function * watchWebinarsFilter() {
    yield takeLatest(webinarsFilterTypes.WEBINARSFILTER_GET, onWebinarsFilterGet);
    yield takeLatest(webinarsFilterTypes.WEBINARSFILTER_SUCCESS, onWebinarsFilterGetResponse);
}

export function * onWebinarsFilterGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onWebinarsFilterGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'webinarsFilter') {
        return yield all([
            put({
                type: webinarsFilterTypes.WEBINARSFILTER_SET,
                payload: response,
            }),
        ]);
    }
}
