import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SALES'),
    SALES_RESET: 'SALES_RESET',
    SALES_SET: 'SALES_SET',
    SALES_GET: 'SALES_GET',
    SALES_SUCCESS: 'SALES_SUCCESS',
};

export const actions = {
    salesSet: (obj) => utils.action(types.SALES_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    sales: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SALES_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.SALES_SET:
        if (action.payload.articles) {
            if (action.payload.articles.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    sales: action.payload.articles,
                    page: action.payload.page,
                    total: action.payload.total,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            sales: [],
            page: 0,
            total: 0,
        });
    case types.SALES_RESET:
        return initialState;
    default:
        return state;
    }
};

const getSales = (state) => state.get('app').get('sales');

export const selectors = {
    getSales: createSelector([getSales], (sales) => sales),
};
