import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('RSMSEARCHRESULTS'),
    RSMSEARCHRESULTS_GET: 'RSMSEARCHRESULTS_GET',
    RSMSEARCHRESULTS_SET: 'RSMSEARCHRESULTS_SET',
    RSMSEARCHRESULTS_SUCCESS: 'RSMSEARCHRESULTS_SUCCESS',
};

export const actions = {
    rsmSearchResultsSet: (obj) => utils.action(types.RSMSEARCHRESULTS_SET, obj),
};

const initialState = fromJS([]);

export default (state = initialState, action) => {
    switch (action.type) {
    case types.RSMSEARCHRESULTS_SET:
        return state;

    case types.RSMSEARCHRESULTS_GET:
        if (!action.payload.sales_promotions) {
            return state;
        }

        return fromJS([
            {
                articles: action.payload.sales_promotions,
                count: action.payload.total,
            },
        ]);

    default:
        return state;
    }
};

export const getRsmSearchResults = (state) => state.get('app').get('rsmSearchResults');

export const selectors = {
    getRsmSearchResults: createSelector([getRsmSearchResults], (rsmSearchResults) => rsmSearchResults),
};
