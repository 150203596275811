import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('CATSALESANDMARKETING'),
    CATSALESANDMARKETING_GET: 'CATSALESANDMARKETING_GET',
    CATSALESANDMARKETING_RESET: 'CATSALESANDMARKETING_RESET',
    CATSALESANDMARKETING_SET: 'CATSALESANDMARKETING_SET',
    CATSALESANDMARKETING_SUCCESS: 'CATSALESANDMARKETING_SUCCESS',
};

export const actions = {
    catSalesAndMarketing: (obj) => utils.action(types.CATSALESANDMARKETING_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.CATSALESANDMARKETING_SET:
        if (action.payload) {
            if (action.payload.categories.length > 0) {
                return fromJS({
                    catSalesAndMarketing: action.payload,
                    loaded: 'complete',
                });
            }
        } else {
            return fromJS({
                loaded: 'empty',
                catSalesAndMarketing: {},
            });
        }

        break;
    case types.CATSALESANDMARKETING_RESET:
        return initialState;
    default:
        return state;
    }
};

const getCatSalesAndMarketing = (state) => state.get('app').get('catSalesAndMarketing');

export const selectors = {
    getCatSalesAndMarketing: createSelector([getCatSalesAndMarketing], (catSalesAndMarketing) => catSalesAndMarketing),
};
