import { all, put, takeLatest } from 'redux-saga/effects';

import { bottomMenuTypes } from '@src/ducks/menus/bottomMenu';
import { mobileMenuTypes } from '@src/ducks/menus/mobileMenu';
import { topMenuTypes } from '@src/ducks/menus/topMenu';

export function * watchMenus() {
    yield takeLatest(bottomMenuTypes.BOTTOM_MENU_GET, onGet);
    yield takeLatest(bottomMenuTypes.BOTTOM_MENU_SUCCESS, onBottomMenuGetResponse);

    yield takeLatest(mobileMenuTypes.MOBILE_MENU_GET, onGet);
    yield takeLatest(mobileMenuTypes.MOBILE_MENU_SUCCESS, onMobileMenuGetResponse);

    yield takeLatest(topMenuTypes.TOP_MENU_GET, onGet);
    yield takeLatest(topMenuTypes.TOP_MENU_SUCCESS, onTopMenuGetResponse);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onBottomMenuGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'bottom_menu') {
        return yield all([
            put({
                type: bottomMenuTypes.BOTTOM_MENU_SET,
                payload: response,
            }),
        ]);
    }
}

function * onMobileMenuGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'mobile_menu') {
        return yield all([
            put({
                type: mobileMenuTypes.MOBILE_MENU_SET,
                payload: response,
            }),
        ]);
    }
}

function * onTopMenuGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'top_menu') {
        return yield all([
            put({
                type: topMenuTypes.TOP_MENU_SET,
                payload: response,
            }),
        ]);
    }
}
