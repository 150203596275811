import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('MENUS'),
    MENUS_SET: 'MENUS_SET',
    MENUS_GET: 'MENUS_GET',
    MENUS_SUCCESS: 'MENUS_SUCCESS',
};

export const actions = {
    menusSet: (obj) => utils.action(types.MENUS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.MENUS_SET:
        if (action.payload.menus) {
            return fromJS({
                menus: action.payload.menus,
                loaded: 'complete',
            });
        }

        return fromJS({
            menus: [],
            loaded: 'empty',
        });

    case types.MENUS_RESET:
        return initialState;

    default:
        return state;
    }
};

const getMenus = (state) => state.get('app').get('menus');

export const selectors = {
    getMenus: createSelector([getMenus], (menus) => menus),
};
