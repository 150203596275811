import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('ALERTS'),
    ALERTS_RESET: 'ALERTS_RESET',
    ALERTS_SET: 'ALERTS_SET',
    ALERTS_GET: 'ALERTS_GET',
    ALERTS_SUCCESS: 'ALERTS_SUCCESS',
};

export const actions = {
    alertsSet: (obj) => utils.action(types.ALERTS_SET, obj),
};

const initialState = fromJS({
    alerts: [],
    // eslint-disable-next-line camelcase
    disaster_recovery: [],
    technology: [],
    highlighted: [],
    total: {},
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.ALERTS_SET:
        if (action.payload.alerts) {
            const _state = state ? state.toJS() : [];
            _state.total.alerts = action.payload.total;
            return fromJS({
                alerts: action.payload.alerts,
                // eslint-disable-next-line camelcase
                disaster_recovery: _state.disaster_recovery,
                technology: _state.technology,
                highlighted: _state.highlighted,
                total: _state.total,
                loaded: 'complete',
            });
        }

        break;
    case 'disaster_recovery':
        if (action.payload.alerts) {
            const _state = state ? state.toJS() : [];
            _state.total['disaster_recovery'] = action.payload.total;
            return fromJS({
                // eslint-disable-next-line camelcase
                disaster_recovery: _state.disaster_recovery.concat(action.payload.alerts),
                alerts: _state.alerts,
                technology: _state.technology,
                highlighted: _state.highlighted,
                total: _state.total,
                loaded: 'complete',
            });
        }

        break;
    case 'technology':
        if (action.payload.alerts) {
            const _state = state ? state.toJS() : [];
            _state.total.technology = action.payload.total;
            return fromJS({
                technology: _state.technology.concat(action.payload.alerts),
                alerts: _state.alerts,
                // eslint-disable-next-line camelcase
                disaster_recovery: _state.disaster_recovery,
                highlighted: _state.highlighted,
                total: _state.total,
                loaded: 'complete',
            });
        }

        break;
    case 'highlighted': {
        if (action.payload.alerts) {
            const _state = state ? state.toJS() : [];
            _state.total.highlighted = action.payload.total;
            return fromJS({
                highlighted: _state.highlighted.concat(action.payload.alerts),
                alerts: _state.alerts,
                // eslint-disable-next-line camelcase
                disaster_recovery: _state.disaster_recovery,
                technology: _state.technology,
                total: _state.total,
                loaded: 'complete',
            });
        }

        const _state = state ? state.toJS() : [];
        return fromJS({
            highlighted: _state.alerts,
            alerts: _state.alerts,
            // eslint-disable-next-line camelcase
            disaster_recovery: _state.disaster_recovery,
            technology: _state.technology,
            total: _state.total,
            loaded: _state.loaded ? 'complete' : 'empty',
        });
    }

    case types.ALERTS_RESET:
        return initialState;

    default:
        return state;
    }
};

const getAlerts = (state) => state.get('app').get('alerts');

export const selectors = {
    getAlerts: createSelector([getAlerts], (alerts) => alerts),
};
