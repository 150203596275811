import { all, put, takeLatest } from 'redux-saga/effects';

import { types as redirectRecordsTypes } from '../ducks/redirect_records';

export function * watchRedirectRecords() {
    yield takeLatest(redirectRecordsTypes.REDIRECT_RECORDS_GET, onRedirectRecordsGet);
    yield takeLatest(redirectRecordsTypes.REDIRECT_RECORDS_SUCCESS, onRedirectRecordsGetResponse);
}

function * onRedirectRecordsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onRedirectRecordsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'redirect_records') {
        return yield all([
            put({
                type: redirectRecordsTypes.REDIRECT_RECORDS_SET,
                payload: response,
            }),
        ]);
    }
}
