import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('CATSYSTEMANDTECH'),
    CATSYSTEMANDTECH_GET: 'CATSYSTEMANDTECH_GET',
    CATSYSTEMANDTECH_RESET: 'CATSYSTEMANDTECH_RESET',
    CATSYSTEMANDTECH_SET: 'CATSYSTEMANDTECH_SET',
    CATSYSTEMANDTECH_SUCCESS: 'CATSYSTEMANDTECH_SUCCESS',
};

export const actions = {
    catSystemAndTechSet: (obj) => utils.action(types.CATSYSTEMANDTECH_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.CATSYSTEMANDTECH_SET:
        if (action.payload) {
            if (action.payload.categories.length > 0) {
                return fromJS({
                    catSystemAndTech: action.payload,
                    loaded: 'complete',
                });
            }
        } else {
            return fromJS({
                catSystemAndTech: {},
                loaded: 'empty',
            });
        }

        break;
    case types.CATSYSTEMANDTECH_RESET:
        return initialState;

    default:
        return state;
    }
};

const getCatSystemAndTech = (state) => state.get('app').get('catSystemAndTech');

export const selectors = {
    getCatSystemAndTech: createSelector([getCatSystemAndTech], (catSystemAndTech) => catSystemAndTech),
};
