import { fromJS } from 'immutable';

import * as utils from '@src/utils/duckHelpers';

const types = {
    ...utils.requestTypes('PRELAUNCH_JOB_AIDS'),
    PRELAUNCH_JOB_AIDS_SET: 'PRELAUNCH_JOB_AIDS_SET',
    PRELAUNCH_JOB_AIDS_GET: 'PRELAUNCH_JOB_AIDS_GET',
    PRELAUNCH_JOB_AIDS_SUCCESS: 'PRELAUNCH_JOB_AIDS_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
    jobAids: [],
    total: 0,
    page: 0,
});

const prelaunchJobAidsReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.PRELAUNCH_JOB_AIDS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.PRELAUNCH_JOB_AIDS_SET:
        return state.merge({
            loaded: 'complete',
            jobAids: action.payload.job_aids,
            total: action.payload.total,
            page: action.payload.page,
        });
    case types.PRELAUNCH_JOB_AIDS_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    types,
    prelaunchJobAidsReducer as default,
};
