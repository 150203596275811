import { all, put, takeLatest } from 'redux-saga/effects';

import { types as jobAidTypes } from '@src/ducks/job_aids';
import { types as monthlyJobAidTypes } from '@src/ducks/jobAids/monthlyJobAids';
import { types as prelaunchJobAidTypes } from '@src/ducks/jobAids/prelaunchJobAids';

function * watchJobAids() {
    yield takeLatest(jobAidTypes.JOB_AIDS_GET, onJobAidsGet);
    yield takeLatest(jobAidTypes.JOB_AIDS_SUCCESS, onJobAidsGetResponse);
}

function * onJobAidsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onJobAidsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'job_aids') {
        return yield all([
            put({
                type: jobAidTypes.JOB_AIDS_SET,
                payload: response,
            }),
        ]);
    }
}

function * watchMonthlyJobAids() {
    yield takeLatest(monthlyJobAidTypes.MONTHLY_JOB_AIDS_GET, onMonthlyJobAidsGet);
    yield takeLatest(monthlyJobAidTypes.MONTHLY_JOB_AIDS_SUCCESS, onMonthlyJobAidsGetResponse);
}

function * onMonthlyJobAidsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onMonthlyJobAidsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'monthly_job_aids') {
        return yield all([
            put({
                type: monthlyJobAidTypes.MONTHLY_JOB_AIDS_SET,
                payload: response,
            }),
        ]);
    }
}

function * watchPrelaunchJobAids() {
    yield takeLatest(prelaunchJobAidTypes.PRELAUNCH_JOB_AIDS_GET, onPrelaunchJobAidsGet);
    yield takeLatest(prelaunchJobAidTypes.PRELAUNCH_JOB_AIDS_SUCCESS, onPrelaunchJobAidsGetResponse);
}

function * onPrelaunchJobAidsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onPrelaunchJobAidsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'prelaunch_job_aids') {
        return yield all([
            put({
                type: prelaunchJobAidTypes.PRELAUNCH_JOB_AIDS_SET,
                payload: response,
            }),
        ]);
    }
}

export {
    watchJobAids,
    watchMonthlyJobAids,
    watchPrelaunchJobAids,
};
