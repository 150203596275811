import { all, put, takeLatest } from 'redux-saga/effects';

import { types as catSystemAndTechTypes } from '../ducks/catSystemAndTech';

export function * watchCatSystemAndTech() {
    yield takeLatest(catSystemAndTechTypes.CATSYSTEMANDTECH_RESET, onCatSystemAndTechReset);
    yield takeLatest(catSystemAndTechTypes.CATSYSTEMANDTECH_GET, onCatSystemAndTechGet);
    yield takeLatest(catSystemAndTechTypes.CATSYSTEMANDTECH_SUCCESS, onCatSystemAndTechGetResponse);
}

export function * onCatSystemAndTechReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onCatSystemAndTechGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onCatSystemAndTechGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'catSystemAndTech') {
        if (!Object.keys(response).length) {
            response = payload.data;
        }

        return yield all([
            put({
                type: catSystemAndTechTypes.CATSYSTEMANDTECH_SET,
                payload: response,
            }),
        ]);
    }
}
