import SetRoute from '../services/setRoute';

const fetchGuides = (fetch, params = '') => ({
    payload: {
        dataset: 'guides',
        action: 'get',
        route: SetRoute + `/api/v1/guides${params}`,
        data: {},
    },
    fetch,
});

const fetchGuidesByState = (fetch, params = '') => ({
    payload: {
        dataset: 'guides_by_state',
        action: 'get',
        route: SetRoute + `/api/v1/guides/by_state${params}`,
        data: {},
    },
    fetch,
});

export {
    fetchGuides,
    fetchGuidesByState,
};
