import { all, put, takeLatest } from 'redux-saga/effects';

import { types as trainingsFilterTypes } from '../ducks/trainingsFilter';

export function * watchTrainingsFilter() {
    yield takeLatest(trainingsFilterTypes.TRAININGSFILTER_GET, onTrainingsFilterGet);
    yield takeLatest(trainingsFilterTypes.TRAININGSFILTER_SUCCESS, onTrainingsFilterGetResponse);
}

export function * onTrainingsFilterGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTrainingsFilterGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'trainingsFilter') {
        return yield all([
            put({
                type: trainingsFilterTypes.TRAININGSFILTER_SET,
                payload: response,
            }),
        ]);
    }
}
