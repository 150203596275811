import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '../ducks/appHistory';

export function * watchAppHistory() {
    yield takeLatest(types.APPHISTORY_GET, onAppHistorySet);
    yield takeLatest(types.APPHISTORY_SUCCESS, onAppHistoryResponse);
}

export function * onAppHistorySet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onAppHistoryResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'appHistory') {
        return yield all([
            put({
                type: types.APPHISTORY_SET,
                payload: response,
            }),
        ]);
    }
}
