import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    historyActions,
    storeActions,
    dynAct,
    getHistory,
} from '@src/ducks';
import { loginURL } from '@src/global/loginURL';
import {
    setAppHistory,
} from '@src/requests';
import {
    ForceAppScrollTopToZero,
} from '@src/services';
import { unique } from '@src/utils/componentHelpers';
import { removeJwt } from '@src/utils/jwtHelpers';

import bootstrap from '@src/css/bootstrap.scss';
import '@src/css/metlife_css/buttons.scss';

import styles from './error.scss';

const mapStateToProps = (state) => ({
    appHistory: getHistory(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        ...dynAct,
        ...storeActions,
        ...historyActions,
    }, dispatch),
});

/**
 * Error401 - 401 error page
 * @param {object} actions Redux Actions
 * @param {object} history App History
 */

class Error401 extends Component {
    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.fetch = unique();
    }

    componentDidMount() {
        const {
            actions,
            history,
        } = this.props;

        ForceAppScrollTopToZero();
        actions.appRequest(setAppHistory(history));
    }

    setLocalStorageToNoneAndRefreshPage() {
        removeJwt();
        window.location.replace(loginURL());
    }

    render() {
        return (
            <div id="home" className={`col-12 ${styles.error_page}`}>
                <div className={bootstrap.container}>
                    <div className={bootstrap.row}>
                        <div className={`${bootstrap.col} ${bootstrap['text-center']}`}>
                            <div className="error-container">
                                <h1>Your Producer News Portal session has ended</h1>
                                <p>For your security, sessions automatically end.</p>
                                <p className="pt-5">
                                    <a className="ml-button-primary" tabIndex={0} onClick={this.setLocalStorageToNoneAndRefreshPage}>Log In Again</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error401);
