import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('RSM'),
    RSM_GET: 'RSM_GET',
    RSM_SET: 'RSM_SET',
    RSM_SUCCESS: 'RSM_SUCCESS',
};

export const actions = {
    rsmSet: (obj) => utils.action(types.RSM_SET, obj),
};

const initialState = fromJS([]);

export default (state = initialState, action) => {
    switch (action.type) {
    case types.RSM_SET:
        return action.payload.data;
    default:
        return state;
    }
};

export const getRsm = (state) => state.get('app').get('rsm');

export const selectors = {
    getRsm: createSelector([getRsm], (rsm) => rsm),
};
