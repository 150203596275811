import { fromJS } from 'immutable';

import * as utils from '../utils/duckHelpers';

const types = {
    ...utils.requestTypes('GUIDES_BY_STATE'),
    GUIDES_BY_STATE_SET: 'GUIDES_BY_STATE_SET',
    GUIDES_BY_STATE_GET: 'GUIDES_BY_STATE_GET',
    GUIDES_BY_STATE_SUCCESS: 'GUIDES_BY_STATE_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
    guides: [],
    page: 0,
    total: 0,
});

const guidesByStateReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.GUIDES_BY_STATE_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.GUIDES_BY_STATE_SET:
        return state.merge({
            loaded: 'complete',
            guides: action.payload.guides,
            page: action.payload.page,
            total: action.payload.total,
        });
    case types.GUIDES_BY_STATE_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    types,
    guidesByStateReducer as default,
};
