import SetRoute from '../services/setRoute';
/**
 * Fetch Job Aids
 * @param {string} fetch page fetch id
 * @param {string} params fetch parameters
 */
const fetchJobAids = (
    fetch,
    params = '',
) => ({
    payload: {
        dataset: 'job_aids',
        action: 'get',
        route: SetRoute + `/api/v1/job_aids${params}`,
        data: {},
    },
    fetch,
});

/**
 * Fetch monthly training call job aids
 * @param {string} fetch page fetch id
 */
const fetchMonthlyJobAids = (fetch) => ({
    payload: {
        dataset: 'monthly_job_aids',
        action: 'get',
        route: SetRoute + '/api/v1/job_aids?rsm_training=monthly_training_call',
        data: {},
    },
    fetch,
});

/**
 * Fetch prelaunch training materials job aids
 * @param {string} fetch page fetch id
 */
const fetchPrelaunchJobAids = (fetch) => ({
    payload: {
        dataset: 'prelaunch_job_aids',
        action: 'get',
        route: SetRoute + '/api/v1/job_aids?rsm_training=prelaunch_training_material',
        data: {},
    },
    fetch,
});

export {
    fetchJobAids,
    fetchMonthlyJobAids,
    fetchPrelaunchJobAids,
};
