const LOGIN_URL = `${process.env.API_HOST_URL}/saml/initiate`;

const loginURL = (returnTo) => {
    if (returnTo) {
        return `${LOGIN_URL}?RelayState=${encodeURI(returnTo)}`;
    }

    return LOGIN_URL;
};

export { loginURL };
