import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SEARCH'),
    SEARCH_RESET: 'SEARCH_RESET',
    SEARCH_SET: 'SEARCH_SET',
    SEARCH_GET: 'SEARCH_GET',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SEARCH_SET:
        return fromJS({
            results: action.payload.search_results,
            total: action.payload.total,
            page: action.payload.page,
            perPage: action.payload.perPage,
            loaded: 'complete',
        });
    case types.SEARCH_GET:
        return fromJS({
            loaded: 'loading',
        });
    case types.SEARCH_RESET:
        return initialState;
    default:
        return state;
    }
};

const getSearch = createSelector(
    identity,
    (state) => state.get('app').get('search'),
);

export const selectors = {
    getSearch,
};
