import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TOPMENUCLICKED'),
    TOPMENUCLICKED_GET: 'TOPMENUCLICKED_GET',
    TOPMENUCLICKED_SET: 'TOPMENUCLICKED_SET',
    TOPMENUCLICKED_SUCCESS: 'TOPMENUCLICKED_SUCCESS',
};

export const actions = {
    topMenuClickedSet: (obj) => utils.action(types.TOPMENUCLICKED_SET, obj),
};

const initialState = { clicked: [] };

/* eslint-disable no-case-declarations */
export default (state = initialState, action) => {
    switch (action.type) {
    case types.TOPMENUCLICKED_SET:
        const index = action.payload.data;
        for (let i = 0; i < state.clicked.length; i++) {
            if (i !== index) {
                state.clicked[i] = false;
            }
        }

        state.clicked[index] = !state.clicked[index];
        return {
            clicked: state.clicked,
        };

    case types.TOPMENUCLICKED_GET:
        return state;

    default:
        return state;
    }
};

export const getTopMenuClicked = (state) => state.get('app').get('topMenuClicked');
