import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    FIELD_MANAGEMENT_NEWS_SELECTIONS: 'FIELD_MANAGEMENT_NEWS_SELECTIONS',
};

export const actions = {
    setFieldManagementNewsSelections: (obj) => utils.action(types.FIELD_MANAGEMENT_NEWS_SELECTIONS, obj),
};

const initialState = fromJS({
    data: {
        currentPage: 1,
        loaded: false,
        searchString: '',
    },
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FIELD_MANAGEMENT_NEWS_SELECTIONS:
        if (action.payload) {
            return fromJS({
                data: action.payload,
            });
        }

        break;
    default:
        return state;
    }
};

const getFieldManagementNewsSelections = (state) => state.get('app').get('fieldManagementNewsSelections');

export const selectors = {
    getFieldManagementNewsSelections: createSelector([getFieldManagementNewsSelections], (data) => data),
};
