import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '../ducks/sandwichMenu';

export function * watchSandwichMenu() {
    yield takeLatest(types.SANDWICHMENU_GET, onSandwichMenuSet);
    yield takeLatest(types.SANDWICHMENU_SUCCESS, onSandwichMenuResponse);
}

export function * onSandwichMenuSet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onSandwichMenuResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'sandwichMenu') {
        return yield all([
            put({
                type: types.SANDWICHMENU_SET,
                payload: response,
            }),
        ]);
    }
}
