import { all, put, takeLatest } from 'redux-saga/effects';

import { types as queryTimeStampsTypes } from '../ducks/queryTimeStamps';

export function * watchQueryTimeStamps() {
    yield takeLatest(queryTimeStampsTypes.QUERYTIMESTAMPS_GET, onQueryTimeStampsGet);
    yield takeLatest(queryTimeStampsTypes.QUERYTIMESTAMPS_SUCCESS, onQueryTimeStampsGetResponse);
}

export function * onQueryTimeStampsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onQueryTimeStampsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'queryTimeStamps') {
        return yield all([
            put({
                type: queryTimeStampsTypes.QUERYTIMESTAMPS_SET,
                payload: response,
            }),
        ]);
    }
}
