import { put, takeLatest } from 'redux-saga/effects';

import { simplePageTypes } from '@src/ducks/simplePage';
import { simplePagesTypes } from '@src/ducks/simplePages';

export function * watchSimplePages() {
    yield takeLatest(simplePagesTypes.SIMPLE_PAGES_GET, onGet);
    yield takeLatest(simplePagesTypes.SIMPLE_PAGES_SUCCESS, onSimplePagesGetResponse);

    yield takeLatest(simplePageTypes.SIMPLE_PAGE_GET, onGet);
    yield takeLatest(simplePageTypes.SIMPLE_PAGE_SUCCESS, onSimplePageGetResponse);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onSimplePagesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'simple_pages') {
        return yield put({
            type: simplePagesTypes.SIMPLE_PAGES_SET,
            payload: response,
        });
    }
}

function * onSimplePageGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'simple_page') {
        return yield put({
            type: simplePageTypes.SIMPLE_PAGE_SET,
            payload: response,
        });
    }
}
