import React from 'react';

import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link, withRouter } from 'react-router-dom';

import ContactUsLink from '@src/components/ContactUsLink';

import '@src/css/metlife_css/buttons.scss';

import styles from './error.scss';

class Error404 extends React.PureComponent {
    static propTypes = {
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        type: PropTypes.string,
    };

    static defaultProps = {
        type: 'page',
    };

    handleBack = (e) => {
        e.preventDefault();

        this.props.history.goBack();
    };

    componentDidMount() {
        const prevTitle = document.title;
        document.title = 'Page Not Found | ' + prevTitle;
    }

    render() {
        const { type } = this.props;

        return (
            <Container id="home" className={styles.error_page}>
                <Row>
                    <Col className="text-center">
                        <div className="error-container">
                            <img src="/img/404-icon.svg" alt="Magnifying Glass" />
                            <h1>Oops!</h1>
                            <h2>That {type} could not be found.</h2>
                            <p>Try another URL or <a className="ml-link" onClick={this.handleBack} >go back to a previous page</a>.</p>
                            <p>For more support please <ContactUsLink>contact us</ContactUsLink>.</p>
                            <p>Or <Link to="/search" className="ml-link">Search</Link> for content here</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(Error404);
