import { all, put, takeLatest } from 'redux-saga/effects';

import { types as alertsTypes } from '../ducks/alerts';

export function * watchAlerts() {
    yield takeLatest(alertsTypes.ALERTS_RESET, onAlertsReset);
    yield takeLatest(alertsTypes.ALERTS_GET, onAlertsGet);
    yield takeLatest(alertsTypes.ALERTS_SUCCESS, onAlertsGetResponse);
}

export function * onAlertsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onAlertsReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onAlertsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'alerts') {
        return yield all([
            put({
                type: alertsTypes.ALERTS_SET,
                payload: response,
            }),
        ]);
    }

    if (payload.action === 'index' && response && payload.dataset === 'alerts') {
        return yield all([
            put({
                type: payload.type,
                payload: response,
            }),
        ]);
    }
}
