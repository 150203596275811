import { all, put, takeLatest } from 'redux-saga/effects';

import { types as productUpdatesTypes } from '../ducks/product_updates';

export function * watchProductUpdates() {
    yield takeLatest(productUpdatesTypes.PRODUCT_UPDATES_GET, onProductUpdatesGet);
    yield takeLatest(productUpdatesTypes.PRODUCT_UPDATES_SUCCESS, onProductUpdatesGetResponse);
}

function * onProductUpdatesGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onProductUpdatesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'product_updates') {
        return yield all([
            put({
                type: productUpdatesTypes.PRODUCT_UPDATES_SET,
                payload: response,
            }),
        ]);
    }
}
