import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '@src/ducks/featuredProductUpdates';

export function * watchFeaturedProductUpdates() {
    yield takeLatest(types.FEATURED_PRODUCT_UPDATES_GET, onGet);
    yield takeLatest(types.FEATURED_PRODUCT_UPDATES_SUCCESS, onSuccess);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onSuccess({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'featured_product_updates') {
        return yield all([
            put({
                type: types.FEATURED_PRODUCT_UPDATES_SET,
                payload: response,
            }),
        ]);
    }
}
