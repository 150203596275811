const status = {
    LOADING: 'loading',
    COMPLETE: 'complete',
    FAILED: 'failed',
    EMPTY: 'empty',
};

const isLoading = (s) => s === status.LOADING;
const isFailed = (s) => s === status.FAILED;
const isComplete = (s) => s === status.COMPLETE;
const isEmpty = (s) => s === status.EMPTY;

export {
    status,
    isLoading,
    isFailed,
    isComplete,
    isEmpty,
};
