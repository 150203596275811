import SetRoute from '../services/setRoute';

const fetchUncheckedAlertsCount = (
    fetch,
    params = '',
) => ({
    payload: {
        dataset: 'unchecked_count',
        action: 'get',
        route: SetRoute + `/api/v1/alert/unchecked_count${params}`,
        data: {},
    },
    fetch,
});

const fetchUncheckedAlertsByCategoryCount = (
    fetch,
    param = '',
) => ({
    payload: {
        dataset: 'unchecked_by_category_count',
        action: 'get',
        type:	`${param}_count`,
        route: SetRoute + `/api/v1/alert/unchecked_by_category_count?category=${param}`,
        data: {},
    },
    fetch,
});

export {
    fetchUncheckedAlertsCount,
    fetchUncheckedAlertsByCategoryCount,
};
