import { all, put, takeLatest } from 'redux-saga/effects';

import { types as featuredFMNTypes } from '@src/ducks/featuredFieldManagementNews';

export function * watchFeaturedFieldManagementNews() {
    yield takeLatest(featuredFMNTypes.FEATURED_FIELD_MANAGEMENT_NEWS_GET, onGet);
    yield takeLatest(featuredFMNTypes.FEATURED_FIELD_MANAGEMENT_NEWS_SUCCESS, onGetResponse);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'featured_field_management_news') {
        return yield all([
            put({
                type: featuredFMNTypes.FEATURED_FIELD_MANAGEMENT_NEWS_SET,
                payload: response,
            }),
        ]);
    }
}
