import { fork, all } from 'redux-saga/effects';

import { dynamicSagas as extraForks } from '../utils/dynamicHelpers';
import { watchAlert } from './alert';
import { watchAlertArticlePage } from './alertArticlePage';
import { watchAlerts } from './alerts';
import { watchApp } from './app';
import { watchAppHistory } from './appHistory';
import { watchCategories } from './categories';
import { watchCatSalesAndMarketing } from './catSalesAndMarketing';
import { watchCatSystemAndTech } from './catSystemAndTech';
import { watchDateRange } from './dateRange';
import { watchDynamic } from './dynamic';
import { watchError } from './error';
import { watchFavoritePages } from './favoritePages';
import { watchFeaturedFieldManagementNews } from './featuredFieldManagementNews';
import { watchFeaturedProductUpdates } from './featuredProductUpdates';
import { watchFeaturedSales } from './featuredSales';
import { watchFieldManagements } from './field_managments';
import { watchFieldManagement } from './fieldManagment';
import { watchFindGuideLines } from './findGuideLines';
import { watchGuide } from './guide';
import { watchGuides, watchGuidesByState } from './guides';
import { watchHomeArticles } from './home_articles';
import {
    watchJobAids,
    watchMonthlyJobAids,
    watchPrelaunchJobAids,
} from './job_aids';
import { watchMenus } from './menus';
import { watchProduct } from './product';
import { watchProductUpdates } from './product_updates';
import { watchProducts } from './products';
import { watchQueryTimeStamps } from './queryTimeStamps';
import { watchRedirectRecords } from './redirect_records';
import { watchRsm } from './rsm';
import { watchRsmSearchResults } from './rsmSearchResults';
import { watchSales } from './sales';
import { watchSalesPromotion } from './sales_promotion';
import { watchSalesPromotions } from './sales_promotions';
import { watchSandwichMenu } from './sandwichMenu';
import { watchSavedMegaQuery } from './savedMegaQuery';
import { watchSearch } from './search';
import { watchSelected } from './selected';
import { watchSimplePages } from './simplePages';
import { watchStates } from './states';
import { watchStaticCohorts } from './staticCohorts';
import { watchStaticFooter } from './staticFooter';
import { watchStaticHome } from './staticHome';
import { watchStatics } from './statics';
import { watchTopMenuClicked } from './topMenuClicked';
import { watchTraining } from './training';
import { watchTrainingPageLoaded } from './trainingPageLoaded';
import { watchTrainings } from './trainings';
import { watchTrainingsAllSystem } from './trainingsAllSystem';
import { watchTrainingsAllTypes } from './trainingsAllTypes';
import { watchTrainingsFilter } from './trainingsFilter';
import { watchUncheckedByCategoryCount } from './unchecked_by_category_count';
import { watchUncheckedCount } from './unchecked_count';
import { watchWebinars } from './webinars';
import { watchWebinarsFilter } from './webinarsFilter';

const Forks = [
    fork(watchApp),
    fork(watchAlert),
    fork(watchAlerts),
    fork(watchCategories),
    fork(watchCatSalesAndMarketing),
    fork(watchCatSystemAndTech),
    fork(watchDateRange),
    fork(watchFavoritePages),
    fork(watchFeaturedFieldManagementNews),
    fork(watchFeaturedProductUpdates),
    fork(watchFeaturedSales),
    fork(watchFieldManagement),
    fork(watchFieldManagements),
    fork(watchGuide),
    fork(watchGuides),
    fork(watchHomeArticles),
    fork(watchJobAids),
    fork(watchMonthlyJobAids),
    fork(watchGuidesByState),
    fork(watchMenus),
    fork(watchPrelaunchJobAids),
    fork(watchProduct),
    fork(watchProducts),
    fork(watchProductUpdates),
    fork(watchRedirectRecords),
    fork(watchRsm),
    fork(watchSavedMegaQuery),
    fork(watchSales),
    fork(watchSalesPromotion),
    fork(watchSalesPromotions),
    fork(watchSearch),
    fork(watchSimplePages),
    fork(watchStates),
    fork(watchStaticCohorts),
    fork(watchStaticFooter),
    fork(watchStaticHome),
    fork(watchStatics),
    fork(watchTraining),
    fork(watchTrainings),
    fork(watchQueryTimeStamps),
    fork(watchUncheckedByCategoryCount),
    fork(watchUncheckedCount),
    fork(watchWebinars),

    fork(watchSelected),

    fork(watchAppHistory),
    fork(watchFindGuideLines),
    fork(watchSandwichMenu),
    fork(watchTopMenuClicked),
    fork(watchTrainingsAllSystem),
    fork(watchTrainingsAllTypes),
    fork(watchTrainingsFilter),
    fork(watchWebinarsFilter),
    fork(watchTrainingPageLoaded),
    fork(watchRsmSearchResults),

    fork(watchAlertArticlePage),
    fork(watchError),
];

const ForkDrawer = () => {
    for (let i = 0; i < extraForks.length; i++) {
        Forks.push(fork(watchDynamic, extraForks[i]));
    }

    return Forks;
};

export default function * Sagas() {
    yield all(ForkDrawer());
}
