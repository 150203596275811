import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('HOME_ARTICLES'),
    HOME_ARTICLES_RESET: 'HOME_ARTICLES_RESET',
    HOME_ARTICLES_SET: 'HOME_ARTICLES_SET',
    HOME_ARTICLES_GET: 'HOME_ARTICLES_GET',
    HOME_ARTICLES_SUCCESS: 'HOME_ARTICLES_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    home_articlesSet: (obj) => utils.action(types.HOME_ARTICLES_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    // eslint-disable-next-line camelcase
    home_articles: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.HOME_ARTICLES_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.HOME_ARTICLES_SET:
        if (action.payload.home_articles) {
            if (action.payload.home_articles.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    // eslint-disable-next-line camelcase
                    home_articles: action.payload.home_articles,
                    total: action.payload.total,
                    page: action.payload.page,
                    perPage: action.payload.perPage,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            page: 0,
            // eslint-disable-next-line camelcase
            home_articles: [],
            total: 0,
        });

    case types.HOME_ARTICLES_RESET:
        return initialState;

    default:
        return state;
    }
};

const getHomeArticles = (state) => state.get('app').get('home_articles');

export const selectors = {
    // eslint-disable-next-line camelcase
    getHomeArticles: createSelector([getHomeArticles], (home_articles) => home_articles),
};
