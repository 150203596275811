import get from 'lodash/get';

import { apiPatch, buildApiURL } from '@src/services/api';

const patchMarkAsRead = (id) => {
    return apiPatch(
        buildApiURL(`/api/v1/alerts/${id}/mark_as_read`),
        {},
    ).then((response) => get(response, ['data', 'alert']));
};

export {
    patchMarkAsRead,
};
