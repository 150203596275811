import { fromJS } from 'immutable';

import * as utils from '../utils/duckHelpers';

const staticFooterTypes = {
    ...utils.requestTypes('STATIC_FOOTER'),
    STATIC_FOOTER_GET: 'STATIC_FOOTER_GET',
    STATIC_FOOTER_SET: 'STATIC_FOOTER_SET',
    STATIC_FOOTER_RESET: 'STATIC_FOOTER_RESET',
    STATIC_FOOTER_SUCCESS: 'STATIC_FOOTER_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
    data: null,
});

const staticFooterReducer = (state = initialState, action) => {
    switch (action.type) {
    case staticFooterTypes.STATIC_FOOTER_GET:
        return state.merge({
            loaded: 'loading',
        });
    case staticFooterTypes.STATIC_FOOTER_SET:
        return state.merge({
            loaded: 'complete',
            data: action.payload.static,
        });
    case staticFooterTypes.STATIC_FOOTER_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    staticFooterReducer as default,
    staticFooterTypes,
};
