import { Taxonomies } from '@src/global/taxonomies';
import SetRoute from '@src/services/setRoute';

export const fetchCatSystemAndTech = (fetch, query = '') => {
    return ({
        payload: {
            dataset: 'catSystemAndTech',
            action: 'get',
            route: SetRoute + `/api/v1/taxonomies/${Taxonomies.systemAndTechnical}/categories${query}`,
            data: {},
        },
        fetch,
    });
};

export default fetchCatSystemAndTech;
