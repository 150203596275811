import SetRoute from '../services/setRoute';

export const fetchProductArticles = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'product_updates',
            action: 'get',
            route: SetRoute + `/api/v1/product_updates${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchProductArticles;
