import { all, put, takeLatest } from 'redux-saga/effects';

import { types as trainingsTypes } from '../ducks/trainings';

export function * watchTrainings() {
    yield takeLatest(trainingsTypes.TRAININGS_GET, onTrainingsGet);
    yield takeLatest(trainingsTypes.TRAININGS_SUCCESS, onTrainingsGetResponse);
}

export function * onTrainingsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTrainingsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'trainings') {
        return yield all([
            put({
                type: trainingsTypes.TRAININGS_SET,
                payload: response,
            }),
        ]);
    }
}
