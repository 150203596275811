import { all, put, takeLatest } from 'redux-saga/effects';

import { types as uncheckedCountTypes } from '../ducks/unchecked_count';

export function * watchUncheckedCount() {
    yield takeLatest(uncheckedCountTypes.UNCHECKED_COUNT_GET, onUncheckedCountGet);
    yield takeLatest(uncheckedCountTypes.UNCHECKED_COUNT_SUCCESS, onUncheckedCountGetResponse);
}

function * onUncheckedCountGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onUncheckedCountGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'unchecked_count') {
        return yield all([
            put({
                type: uncheckedCountTypes.UNCHECKED_COUNT_SET,
                payload: response,
            }),
        ]);
    }
}
