import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ContactUsLink = (props) => {
    const {
        children,
        className = 'ml-link',
    } = props;

    return (
        <Link to="/contact-us" className={className}>{children}</Link>
    );
};

ContactUsLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ContactUsLink;
