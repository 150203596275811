import SetRoute from '../services/setRoute';

export const fetchFieldManagement = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'field_management',
            action: 'get',
            route: SetRoute + `/api/v1/field_management_news/${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchFieldManagement;
