import SetRoute from '../services/setRoute';

export const fetchAlert = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'alert',
            action: 'get',
            route: SetRoute + `/api/v1/alerts${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchAlert;
