import SetRoute from '../services/setRoute';
/**
 * ## fetchTrainings
 * fetch trainings endpoint
 * @param {string} fetch page fetch id
 * @param {string} params fetch parameters
 */
export const fetchTrainings = (
    fetch,
    params = '',
) => ({
    payload: {
        dataset: 'trainings',
        action: 'get',
        route: SetRoute + `/api/v1/trainings${params}`,
        data: {},
    },
    fetch,
});

export default fetchTrainings;
