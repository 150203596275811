const currentJwt = () => {
    return window.localStorage.getItem('jwt');
};

const refreshJwt = (newJwt) => {
    const agentToken = window.localStorage.getItem('agent_jwt');
    if (agentToken) {
        window.localStorage.setItem('agent_jwt', newJwt);
    }

    window.localStorage.setItem('jwt', newJwt);
};

const removeJwt = () => {
    window.localStorage.removeItem('agent_jwt');
    window.localStorage.removeItem('admin_jwt');
    window.localStorage.removeItem('jwt');
};

const isImpersonate = () => {
    const agentToken = window.localStorage.getItem('agent_jwt');
    const adminToken = window.localStorage.getItem('admin_jwt');

    return Boolean(agentToken) && Boolean(adminToken);
};

const stopImpersonate = () => {
    removeJwt();
};

export {
    currentJwt,
    refreshJwt,
    removeJwt,
    isImpersonate,
    stopImpersonate,
};
