import { put } from 'redux-saga/effects';

import { types as errorType } from '@src/ducks/error';

export function sagaAction(payload, data) {
    const { action, dataset } = payload;

    return {
        type: `${dataset.toUpperCase()}_${action.toUpperCase()}`,
        ...data,
    };
}

export function sagaResponse(payload, data) {
    const { dataset } = payload;

    return {
        type: `${dataset.toUpperCase()}_SUCCESS`,
        ...data,
    };
}

export function sagaFailure(payload, data) {
    const { dataset } = payload;

    return {
        type: `${dataset.toUpperCase()}_FAILURE`,
        ...data,
    };
}

export function * handleSagaFailure(error) {
    /* eslint-disable no-console */
    console.log('*********APP ERROR********');
    console.error(error);
    console.error(error.message);
    console.log('****************************');
    /* eslint-enable no-console */

    if (error.response) {
        if (error.response.status === 401) {
            /* === inactivity === */
            // eslint-disable-next-line no-console
            console.log(error.response);
            yield put({
                type: errorType.ERROR_401,
                payload: error,
            });
        } else if (error.response.status === 403) {
            /* === unauthorized  === */
            yield put({
                type: errorType.ERROR_403,
                payload: error,
                fetch,
            });
        } else if (error.response.status === 500) {
            /* === network error  === */
            yield put({
                type: errorType.ERROR_500,
                payload: error,
                fetch,
            });
        }
    } else if (error.message === 'Network Error') {
        yield put({
            type: errorType.ERROR_500,
            payload: error,
            fetch,
        });
    }

    return yield error;
}
