import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '@src/ducks/search';

export function * watchSearch() {
    yield takeLatest(types.SEARCH_GET, onGet);
    yield takeLatest(types.SEARCH_SUCCESS, onSuccess);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onSuccess({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'search') {
        return yield all([
            put({
                type: types.SEARCH_SET,
                payload: response,
            }),
        ]);
    }
}
