import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('PRODUCT_UPDATES'),
    PRODUCT_UPDATES_RESET: 'PRODUCT_UPDATES_RESET',
    PRODUCT_UPDATES_SET: 'PRODUCT_UPDATES_SET',
    PRODUCT_UPDATES_GET: 'PRODUCT_UPDATES_GET',
    PRODUCT_UPDATES_SUCCESS: 'PRODUCT_UPDATES_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    product_updatesSet: (obj) => utils.action(types.PRODUCT_UPDATES_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    // eslint-disable-next-line camelcase
    product_updates: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.PRODUCT_UPDATES_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.PRODUCT_UPDATES_SET:
        if (action.payload.product_updates) {
            if (action.payload.product_updates.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    // eslint-disable-next-line camelcase
                    product_updates: action.payload.product_updates,
                    total: action.payload.total,
                    page: action.payload.page,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            page: 0,
            // eslint-disable-next-line camelcase
            product_updates: [],
            total: 0,
        });

    case types.PRODUCT_UPDATES_RESET:
        return initialState;

    default:
        return state;
    }
};

const getProductUpdates = (state) => state.get('app').get('product_updates');

export const selectors = {
    // eslint-disable-next-line camelcase
    getProductUpdates: createSelector([getProductUpdates], (product_updates) => product_updates),
};
