import React from 'react';

import PropTypes from 'prop-types';

import Error500 from '../containers/errors/500error';
import Loading from './loader';

const IsLoadingComponent = ({ isLoading, error }) => {
    if (isLoading) {
        return <Loading />;
    }

    // Handle errors as a system error
    if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable no-console */
        console.log('******  500  ******');
        console.error(error);
        console.log('------  500  ------');
        /* eslint-enable no-console */
    }

    return <Error500 />;
};

IsLoadingComponent.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.object,
};

export default IsLoadingComponent;

