import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('FEATURED_FIELD_MANAGEMENT_NEWS'),
    FEATURED_FIELD_MANAGEMENT_NEWS_GET: 'FEATURED_FIELD_MANAGEMENT_NEWS_GET',
    FEATURED_FIELD_MANAGEMENT_NEWS_SET: 'FEATURED_FIELD_MANAGEMENT_NEWS_SET',
    FEATURED_FIELD_MANAGEMENT_NEWS_SUCCESS: 'FEATURED_FIELD_MANAGEMENT_NEWS_SUCCESS',
};

export const actions = {
    featuredFieldManagementNewsSet: (obj) => utils.action(types.FEATURED_FIELD_MANAGEMENT_NEWS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FEATURED_FIELD_MANAGEMENT_NEWS_SET:
        return fromJS({
            fieldManagementNews: action.payload.field_management_news,
            page: action.payload.page,
            total: action.payload.total,
            loaded: 'complete',
        });
    default:
        return state;
    }
};

const getFeaturedFieldManagementNews = createSelector(
    identity,
    (state) => state.getIn(['app', 'featuredFieldManagementNews']),
);

export const selectors = {
    getFeaturedFieldManagementNews,
};
