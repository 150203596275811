import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TRAININGSALLSYSTEMS'),
    TRAININGSALLSYSTEMS_GET: 'TRAININGSALLSYSTEMS_GET',
    TRAININGSALLSYSTEMS_SET: 'TRAININGSALLSYSTEMS_SET',
    TRAININGSALLSYSTEMS_SUCCESS: 'TRAININGSALLSYSTEMS_SUCCESS',
};

export const actions = {
    trainingsAllSystemsSet: (obj) => utils.action(types.TRAININGSALLSYSTEMS_SET, obj),
};

const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAININGSALLSYSTEMS_SET:
        return action.payload.data;

    case types.TRAININGSALLSYSTEMS_GET:
        return state;

    default:
        return state;
    }
};

export const getTrainingsAllSystems = (state) => state.get('app').get('trainingsAllSystems');
