import { all, put, takeLatest } from 'redux-saga/effects';

import { types as alertArticlePageTypes } from '../ducks/alertArticlePage';

export function * watchAlertArticlePage() {
    yield takeLatest(alertArticlePageTypes.ALERTARTICLEPAGE_GET, onAlertArticlePageGet);
    yield takeLatest(alertArticlePageTypes.ALERTARTICLEPAGE_SUCCESS, onAlertArticlePageGetResponse);
}

function * onAlertArticlePageGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onAlertArticlePageGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'alertArticlePage') {
        return yield all([
            put({
                type: alertArticlePageTypes.ALERTARTICLEPAGE_SET,
                payload: response,
            }),
        ]);
    }
}
