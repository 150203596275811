const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getNameMonthDayYear = (timeGiven) => {
    const givenDate = new Date(timeGiven.replace(/\s/g, 'T'));

    const day = givenDate.getDate();
    const month = givenDate.getMonth();
    const year = givenDate.getFullYear();
    return `${MONTHS[month]} ${day}, ${year}`;
};

export default getNameMonthDayYear;
