import { fromJS } from 'immutable';

import { status } from '@src/global/status';

import * as utils from '../utils/duckHelpers';

const categoriesTypes = utils.requestTypes('CATEGORIES');

const initialState = fromJS({
    loaded: status.LOADING,
    categories: [],
});

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
    case categoriesTypes.CATEGORIES_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case categoriesTypes.CATEGORIES_SET:
        return state.merge({
            loaded: status.COMPLETE,
            categories: action.payload.categories,
            error: null,
        });
    case categoriesTypes.CATEGORIES_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            categories: [],
            error: action.error,
        });
    case categoriesTypes.CATEGORIES_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    categoriesReducer as default,
    categoriesTypes,
};
