import SetRoute from '../services/setRoute';

export const fetchGuide = (
    fetch,
    params = '',
) => ({
    payload: {
        dataset: 'guide',
        action: 'get',
        route: SetRoute + `/api/v1/guides${params}`,
        data: {},
    },
    fetch,
});

export default fetchGuide;
