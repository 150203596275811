import SetRoute from '../services/setRoute';
/**
 * ## fetchTraining
 * fetch trainings endpoint
 * @param {string} fetch page fetch id
 * @param {string} params fetch parameters
 */
export const fetchTraining = (
    fetch,
    params = '',
) => ({
    payload: {
        dataset: 'training',
        action: 'get',
        route: SetRoute + `/api/v1/${params}`,
        data: {},
    },
    fetch,
});

export default fetchTraining;
