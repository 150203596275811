import { all, put, takeLatest } from 'redux-saga/effects';

import { types as featuredSalesTypes } from '@src/ducks/featuredSales';

export function * watchFeaturedSales() {
    yield takeLatest(featuredSalesTypes.FEATURED_SALES_RESET, onReset);
    yield takeLatest(featuredSalesTypes.FEATURED_SALES_GET, onGet);
    yield takeLatest(featuredSalesTypes.FEATURED_SALES_SUCCESS, onGetResponse);
}

function * onReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'featured_sales') {
        return yield all([
            put({
                type: featuredSalesTypes.FEATURED_SALES_SET,
                payload: response,
            }),
        ]);
    }
}
