import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SALES_PROMOTIONS'),
    SALES_PROMOTIONS_GET: 'SALES_PROMOTIONS_GET',
    SALES_PROMOTIONS_SET: 'SALES_PROMOTIONS_SET',
    SALES_PROMOTIONS_SUCCESS: 'SALES_PROMOTIONS_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    sales_promotionsSet: (obj) => utils.action(types.SALES_PROMOTIONS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SALES_PROMOTIONS_SET:
        return state;

    case types.SALES_PROMOTIONS_GET:
        if (action.payload.sales_promotions) {
            if (action.payload.sales_promotions.length > 0) {
                return fromJS({
                    // eslint-disable-next-line camelcase
                    sales_promotions: action.payload.sales_promotions,
                    count: action.payload.total,
                    loaded: 'complete',
                });
            }
        }

        return fromJS({
            loaded: 'empty',
        });

    default:
        return state;
    }
};

// eslint-disable-next-line camelcase
export const getSales_promotions = (state) => state.get('app').get('sales_promotions');

export const selectors = {
    // eslint-disable-next-line camelcase
    getSales_promotions: createSelector([getSales_promotions], (sales_promotions) => sales_promotions),
};
