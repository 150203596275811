import { all, put, takeLatest } from 'redux-saga/effects';

import { staticFooterTypes } from '@src/ducks/staticFooter';

export function * watchStaticFooter() {
    yield takeLatest(staticFooterTypes.STATIC_FOOTER_RESET, onReset);
    yield takeLatest(staticFooterTypes.STATIC_FOOTER_GET, onGet);
    yield takeLatest(staticFooterTypes.STATIC_FOOTER_SUCCESS, onGetResponse);
}

function * onReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'static_footer') {
        return yield all([
            put({
                type: staticFooterTypes.STATIC_FOOTER_SET,
                payload: response,
            }),
        ]);
    }
}
