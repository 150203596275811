// DynamicSagas must be plural
export const dynamicSagas = [
    /*
	== Favorites Top Header ==
	Test Favorites for Top Header */
    'headerFavorites',
    /*
	== Last Alerts Checked ==
	Test Last Time Alerts Was Checked */
    'lastAlertChecked',
];

export function dynTypes(duck, type) {
    return `${duck.toUpperCase()}_${type.toUpperCase()}`;
}

export function dynOn(duck, type) {
    return `on${duck + type.toUpperCase()}`;
}
