import { all, put, takeLatest } from 'redux-saga/effects';

import { types as trainingsAllTypesTypes } from '../ducks/trainingsAllTypes';

export function * watchTrainingsAllTypes() {
    yield takeLatest(trainingsAllTypesTypes.TRAININGSALLTYPES_GET, onTrainingsAllTypesGet);
    yield takeLatest(trainingsAllTypesTypes.TRAININGSALLTYPES_SUCCESS, onTrainingsAllTypesGetResponse);
}

export function * onTrainingsAllTypesGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTrainingsAllTypesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'trainingsAllTypes') {
        return yield all([
            put({
                type: trainingsAllTypesTypes.TRAININGSALLTYPES_SET,
                payload: response,
            }),
        ]);
    }
}
