import { all, put, takeLatest } from 'redux-saga/effects';

import { types as catSalesAndMarketingTypes } from '../ducks/catSalesAndMarketing';

export function * watchCatSalesAndMarketing() {
    yield takeLatest(catSalesAndMarketingTypes.CATSALESANDMARKETING_RESET, onCatSalesAndMarketingReset);
    yield takeLatest(catSalesAndMarketingTypes.CATSALESANDMARKETING_GET, onCatSalesAndMarketingGet);
    yield takeLatest(catSalesAndMarketingTypes.CATSALESANDMARKETING_SUCCESS, onCatSalesAndMarketingGetResponse);
}

export function * onCatSalesAndMarketingReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onCatSalesAndMarketingGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onCatSalesAndMarketingGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'catSalesAndMarketing') {
        if (!Object.keys(response).length) {
            response = payload.data;
        }

        return yield all([
            put({
                type: catSalesAndMarketingTypes.CATSALESANDMARKETING_SET,
                payload: response,
            }),
        ]);
    }
}
