import SetRoute from '../services/setRoute';

export const fetchWebinars = (
    fetch,
    param = '',
) => ({
    payload: {
        dataset: 'webinars',
        action: 'get',
        route: SetRoute + `/api/v1/webinars${param}`,
        data: {},
    },
    fetch,
});

export default fetchWebinars;
