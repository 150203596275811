import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SANDWICHMENU'),
    SANDWICHMENU_GET: 'SANDWICHMENU_GET',
    SANDWICHMENU_SET: 'SANDWICHMENU_SET',
    SANDWICHMENU_SUCCESS: 'SANDWICHMENU_SUCCESS',
};

export const actions = {
    sandwichMenuSet: (obj) => utils.action(types.SANDWICHMENU_SET, obj),
};

const initialState = 'notclicked';

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SANDWICHMENU_SET:
        return state === 'clicked' ? 'notclicked' : 'clicked';

    case types.SANDWICHMENU_GET:
        return state;

    default:
        return state;
    }
};

export const getSandwichMenu = (state) => state.get('app').get('sandwichMenu');
