import SetRoute from '../services/setRoute';

export const fetchAlerts = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'alerts',
            action: 'get',
            route: SetRoute + `/api/v1/alerts${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchAlerts;
