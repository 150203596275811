import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('FEATURED_PRODUCT_UPDATES'),
    FEATURED_PRODUCT_UPDATES_RESET: 'FEATURED_PRODUCT_UPDATES_RESET',
    FEATURED_PRODUCT_UPDATES_SET: 'FEATURED_PRODUCT_UPDATES_SET',
    FEATURED_PRODUCT_UPDATES_GET: 'FEATURED_PRODUCT_UPDATES_GET',
    FEATURED_PRODUCT_UPDATES_SUCCESS: 'FEATURED_PRODUCT_UPDATES_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FEATURED_PRODUCT_UPDATES_SET:
        return fromJS({
            productUpdates: action.payload.product_updates,
            total: action.payload.total,
            page: action.payload.page,
            loaded: 'complete',
        });
    case types.FEATURED_PRODUCT_UPDATES_RESET:
        return initialState;
    default:
        return state;
    }
};

const getFeaturedProductUpdates = createSelector(
    identity,
    (state) => state.get('app').get('featuredProductUpdates'),
);

export const selectors = {
    getFeaturedProductUpdates,
};
