import SetRoute from '../services/setRoute';

export const fetchFeaturedProductUpdates = (fetch) => ({
    payload: {
        dataset: 'featured_product_updates',
        action: 'get',
        route: SetRoute + '/api/v1/product_updates?is_featured=true',
        data: {},
    },
    fetch,
});
