import { all, put, takeLatest } from 'redux-saga/effects';

import { types as rsmSearchResultsTypes } from '../ducks/rsmSearchResults';

export function * watchRsmSearchResults() {
    yield takeLatest(rsmSearchResultsTypes.RSMSEARCHRESULTS_GET, onRsmSearchResultsGet);
    yield takeLatest(rsmSearchResultsTypes.RSMSEARCHRESULTS_SUCCESS, onRsmSearchResultsGetResponse);
}

export function * onRsmSearchResultsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onRsmSearchResultsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'rsmSearchResults') {
        return yield all([
            put({
                type: rsmSearchResultsTypes.RSMSEARCHRESULTS_GET,
                payload: response,
            }),
        ]);
    }
}
