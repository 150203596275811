import { all, put, takeLatest } from 'redux-saga/effects';

import { types as uncheckedByCategoryCountTypes } from '../ducks/unchecked_by_category_count';

export function * watchUncheckedByCategoryCount() {
    yield takeLatest(
        uncheckedByCategoryCountTypes.UNCHECKED_BY_CATEGORY_COUNT_RESET, onUncheckedByCategoryCountReset,
    );
    yield takeLatest(
        uncheckedByCategoryCountTypes.UNCHECKED_BY_CATEGORY_COUNT_GET, onUncheckedByCategoryCountGet,
    );
    yield takeLatest(
        uncheckedByCategoryCountTypes.UNCHECKED_BY_CATEGORY_COUNT_SUCCESS, onUncheckedByCategoryCountGetResponse,
    );
}

export function * onUncheckedByCategoryCountReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onUncheckedByCategoryCountGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onUncheckedByCategoryCountGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'unchecked_by_category_count') {
        return yield all([
            put({
                type: payload.type,
                payload: response,
            }),
        ]);
    }
}
