/* eslint-disable camelcase */
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('FIELD_MANAGEMENTS'),
    FIELD_MANAGEMENTS_GET: 'FIELD_MANAGEMENTS_GET',
    FIELD_MANAGEMENTS_SET: 'FIELD_MANAGEMENTS_SET',
    FIELD_MANAGEMENTS_SUCCESS: 'FIELD_MANAGEMENTS_SUCCESS',
};

export const actions = {
    field_managementsSet: (obj) => utils.action(types.FIELD_MANAGEMENTS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    fieldManagementNews: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FIELD_MANAGEMENTS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.FIELD_MANAGEMENTS_SET:
        if (action.payload.field_management_news) {
            if (action.payload.field_management_news.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    fieldManagementNews: action.payload.field_management_news,
                    page: action.payload.page,
                    total: action.payload.total,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            fieldManagementNews: [],
            page: 0,
            total: 0,
        });
    default:
        return state;
    }
};

export const getFieldManagements = (state) => state.get('app').get('field_managements');

export const selectors = {
    getFieldManagements: createSelector([getFieldManagements], (field_managements) => field_managements),
};
