import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('REDIRECT_RECORDS'),
    REDIRECT_RECORDS_RESET: 'REDIRECT_RECORDS_RESET',
    REDIRECT_RECORDS_SET: 'REDIRECT_RECORDS_SET',
    REDIRECT_RECORDS_GET: 'REDIRECT_RECORDS_GET',
    REDIRECT_RECORDS_SUCCESS: 'REDIRECT_RECORDS_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    redirect_recordsSet: (obj) => utils.action(types.REDIRECT_RECORDS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    // eslint-disable-next-line camelcase
    redirect_records: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.REDIRECT_RECORDS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case types.REDIRECT_RECORDS_SET:
        if (action.payload.redirect_records) {
            if (action.payload.redirect_records.length > 0) {
                return state.merge({
                    loaded: 'complete',
                    // eslint-disable-next-line camelcase
                    redirect_records: action.payload.redirect_records,
                    total: action.payload.total,
                    page: action.payload.page,
                    perPage: action.payload.perPage,
                });
            }
        }

        return state.merge({
            loaded: 'empty',
            page: 0,
            // eslint-disable-next-line camelcase
            redirect_records: [],
            total: 0,
        });

    case types.REDIRECT_RECORDS_RESET:
        return initialState;

    default:
        return state;
    }
};

const getRedirectRecords = (state) => state.get('app').get('redirect_records');

export const selectors = {
    // eslint-disable-next-line camelcase
    getRedirectRecords: createSelector([getRedirectRecords], (redirect_records) => redirect_records),
};
