import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('WEBINARSFILTER'),
    WEBINARSFILTER_GET: 'WEBINARSFILTER_GET',
    WEBINARSFILTER_SET: 'WEBINARSFILTER_SET',
    WEBINARSFILTER_SUCCESS: 'WEBINARSFILTER_SUCCESS',
};

export const actions = {
    webinarFilterSet: (obj) => utils.action(types.WEBINARSFILTER_SET, obj),
};

const initialState = fromJS([]);

const addAllClicked = (state, payload = '') => {
    const newState = state;
    const category = payload.category;
    let data = payload.data;

    data = data.replace(/<(?:.|\n)*?>/gm, '');
    if (newState[category]) {
        /* -- startDate and endDate should only have 1 item -- */
        if (category === 'startDate' || category === 'endDate') {
            newState[category] = [data];
            return newState;
        }

        for (let i = 0; i < newState[category].length; i++) {
            if (newState[category][i] === data) {
                newState[category].splice((i), 1);
                return newState;
            }
        }
    } else {
        newState[category] = [];
    }

    newState[category].push(data);
    return newState;
};

const constructQuery = ({ product, startDate, endDate, types, platform, page }) => {
    let query = '?';
    const queryList = [product, startDate, endDate, types, platform, page];
    const queryName = ['product', 'startDate', 'endDate', 'types', 'platforms', 'page'];
    let defindeCounter = 0;
    for (let i = 0; i < queryList.length; i++) {
        if (queryList[i]) {
            if (defindeCounter > 0) {
                query += '&';
            }

            query += queryName[i] + '=';
            for (let j = 0; j < queryList[i].length; j++) {
                query += encodeURIComponent(queryList[i][j]);
                if (queryList[i].length !== (j + 1)) {
                    query += ',';
                }
            }

            defindeCounter++;
        }
    }

    return query;
};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.WEBINARSFILTER_SET:
        if (action.payload.data.category === 'page') {
            if (state.getIn([0])) {
                state = state.getIn([0, 'selected']).toJS();
                state.page = action.payload.data.data;
            } else {
                state = state.toJS();
                state.page = action.payload.data.data;
            }
        } else if (state.getIn([0])) {
            state = addAllClicked(state.getIn([0, 'selected']).toJS(), action.payload.data);
        } else {
            state = addAllClicked(state.toJS(), action.payload.data);
        }

        return fromJS({
            product: state.product,
            startDate: state.startDate,
            endDate: state.endDate,
            types: state.types,
            platform: state.platform,
            query: constructQuery(state),
            page: state.page,
        });

    case types.WEBINARSFILTER_GET:
        return state;

    default:
        return state;
    }
};

export const getWebinarFilter = (state) => state.get('app').get('webinarsFilter');

export const selectors = {
    getWebinarFilter: createSelector([getWebinarFilter], (webinarsFilter) => webinarsFilter),
};
