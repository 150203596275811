export const setAppHistory = (history) => ({
    payload: {
        dataset: 'appHistory',
        action: 'set',
        data: history,
        route: '',
    },
});

export default setAppHistory;
