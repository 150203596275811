const setUserInfo = () => ({
    payload: {
        dataset: 'user_info',
        action: 'set',
        data: null,
        route: '',
    },
});

export default setUserInfo;
