import { all, put, takeLatest } from 'redux-saga/effects';

import { types as guideTypes } from '../ducks/guide';

export function * watchGuide() {
    yield takeLatest(guideTypes.GUIDE_GET, onGuideGet);
    yield takeLatest(guideTypes.GUIDE_SUCCESS, onGuideGetResponse);
}

export function * onGuideGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onGuideGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'guide') {
        return yield all([
            put({
                type: guideTypes.GUIDE_SET,
                payload: response,
            }),
        ]);
    }
}
