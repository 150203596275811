import SetRoute from '../services/setRoute';

export const fetchFeaturedFieldManagementNews = (
    fetch,
) => (
    {
        payload: {
            dataset: 'featured_field_management_news',
            action: 'get',
            route: SetRoute + '/api/v1/field_management_news?is_featured=true',
            data: {},
        },
        fetch,
    }
);
