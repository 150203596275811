import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '../ducks/error';

export function * watchError() {
    yield takeLatest(types.ERROR_SUCCESS, onErrorLoadedResponse);
}

export function * onErrorLoadedResponse({ payload }) {
    payload.method = 'set';

    if (payload.action === 'set' && payload.dataset === 'error') {
        return yield all([
            put({
                type: types.ERROR_LOADED,
                payload: payload.data,
            }),
        ]);
    }
}
