export const resetStatics = (fetch) => ({
    payload: {
        dataset: 'statics',
        action: 'reset',
        route: '',
        data: {},
    },
    fetch,
});

export default resetStatics;
