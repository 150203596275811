import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const types = utils.requestTypes('STATICS');

const initialState = fromJS({
    loaded: status.LOADING,
    data: null,
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.STATICS_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.STATICS_SET:
        return state.merge({
            loaded: status.COMPLETE,
            data: action.payload.static,
            error: null,
        });
    case types.STATICS_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            data: null,
            error: action.error,
        });
    case types.STATICS_RESET:
        return initialState;
    default:
        return state;
    }
};

export const getStatics = (state) => state.getIn(['app', 'statics']);

export const selectors = {
    getStatics: createSelector(identity, getStatics),
};
