import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('UNCHECKED_COUNT'),
    UNCHECKED_COUNT_SET: 'UNCHECKED_COUNT_SET',
    UNCHECKED_COUNT_GET: 'UNCHECKED_COUNT_GET',
    UNCHECKED_COUNT_SUCCESS: 'UNCHECKED_COUNT_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    unchecked_countSet: (obj) => utils.action(types.UNCHECKED_COUNT_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.UNCHECKED_COUNT_SET:
        if (action.payload.alert_count) {
            return fromJS({
                // eslint-disable-next-line camelcase
                unchecked_count: action.payload.alert_count,
                total: action.payload.total,
                loaded: 'complete',
            });
        }

        return fromJS({
            // eslint-disable-next-line camelcase
            unchecked_count: 0,
            loaded: 'empty',
        });

    case types.UNCHECKED_COUNT_RESET:
        return initialState;

    default:
        return state;
    }
};

// eslint-disable-next-line camelcase
const getUnchecked_count = (state) => state.get('app').get('unchecked_count');

export const selectors = {
    // eslint-disable-next-line camelcase
    getUnchecked_count: createSelector([getUnchecked_count], (unchecked_count) => unchecked_count),
};
