import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const types = utils.requestTypes('TRAINING');

const initialState = fromJS({
    loaded: status.LOADING,
    training: {},
    jobAid: {},
    webinar: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAINING_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.TRAINING_SET: {
        if (action.payload.training) {
            return state.merge({
                loaded: status.COMPLETE,
                training: action.payload.training,
                jobAid: null,
                webinar: null,
                error: null,
            });
        }

        if (action.payload.job_aid) {
            return state.merge({
                loaded: status.COMPLETE,
                training: null,
                jobAid: action.payload.job_aid,
                webinar: null,
                error: null,
            });
        }

        if (action.payload.webinar) {
            return state.merge({
                loaded: status.COMPLETE,
                training: null,
                jobAid: null,
                webinar: action.payload.webinar,
                error: null,
            });
        }

        return state.merge({
            loaded: status.COMPLETE,
            training: null,
            jobAid: null,
            webinar: null,
            error: null,
        });
    }

    case types.TRAINING_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            training: null,
            jobAid: null,
            webinar: null,
            error: action.error,
        });
    case types.TRAINING_RESET:
        return initialState;
    default:
        return state;
    }
};

const getTraining = (state) => state.getIn(['app', 'training']);

export const selectors = {
    getTraining: createSelector([getTraining], (training) => training),
};
