import { fromJS } from 'immutable';

import * as utils from '@src/utils/duckHelpers';

const allProductsTypes = {
    ...utils.requestTypes('ALL_PRODUCTS'),
};

const initialState = fromJS({
    loaded: 'loading',
    products: [],
});

const allProductsReducer = (state = initialState, action) => {
    switch (action.type) {
    case allProductsTypes.ALL_PRODUCTS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case allProductsTypes.ALL_PRODUCTS_SET:
        return state.merge({
            products: action.payload.products,
            loaded: 'complete',
        });
    default:
        return state;
    }
};

export {
    allProductsTypes,
    allProductsReducer as default,
};
