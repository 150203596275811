import { fromJS } from 'immutable';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

const favoritePagesTypes = {
    ...utils.requestTypes('FAVORITE_PAGES'),
    FAVORITE_PAGES_CREATE: 'FAVORITE_PAGES_CREATE',
    FAVORITE_PAGES_CREATE_SUCCESS: 'FAVORITE_PAGES_CREATE_SUCCESS',
    FAVORITE_PAGES_CREATE_FAILURE: 'FAVORITE_PAGES_CREATE_FAILURE',
    FAVORITE_PAGES_REMOVE: 'FAVORITE_PAGES_REMOVE',
    FAVORITE_PAGES_REMOVE_SUCCESS: 'FAVORITE_PAGES_REMOVE_SUCCESS',
    FAVORITE_PAGES_REMOVE_FAILURE: 'FAVORITE_PAGES_REMOVE_FAILURE',
    FAVORITE_PAGES_UPDATE_POSITION: 'FAVORITE_PAGES_UPDATE_POSITION',
    FAVORITE_PAGES_UPDATE_POSITION_SUCCESS: 'FAVORITE_PAGES_UPDATE_POSITION_SUCCESS',
    FAVORITE_PAGES_UPDATE_POSITION_FAILURE: 'FAVORITE_PAGES_UPDATE_POSITION_FAILURE',
};

const favoritePagesActions = {
    request: (params) => utils.action(favoritePagesTypes.FAVORITE_PAGES_REQUEST, { payload: params }),
    success: (response) => utils.action(favoritePagesTypes.FAVORITE_PAGES_SUCCESS, { payload: response }),
    failure: (error) => utils.action(favoritePagesTypes.FAVORITE_PAGES_FAILURE, { error }),
    create: (data) => utils.action(favoritePagesTypes.FAVORITE_PAGES_CREATE, { payload: data }),
    createSuccess: (response) => utils.action(favoritePagesTypes.FAVORITE_PAGES_CREATE_SUCCESS, { payload: response }),
    createFailure: (error) => utils.action(favoritePagesTypes.FAVORITE_PAGES_CREATE_FAILURE, { error }),
    remove: (id) => utils.action(favoritePagesTypes.FAVORITE_PAGES_REMOVE, { payload: { id } }),
    removeSuccess: (response) => utils.action(favoritePagesTypes.FAVORITE_PAGES_REMOVE_SUCCESS, { payload: response }),
    removeFailure: (error) => utils.action(favoritePagesTypes.FAVORITE_PAGES_REMOVE_FAILURE, { error }),
    updatePosition: (id, position) => utils.action(favoritePagesTypes.FAVORITE_PAGES_UPDATE_POSITION, { payload: { id, position } }),
    updatePositionSuccess: (response) => utils.action(favoritePagesTypes.FAVORITE_PAGES_UPDATE_POSITION_SUCCESS, { payload: response }),
    updatePositionFailure: (error) => utils.action(favoritePagesTypes.FAVORITE_PAGES_UPDATE_POSITION_FAILURE, { error }),
};

const initialState = fromJS({
    loaded: status.LOADING,
    favoritePages: [],
    page: 0,
    total: 0,
    error: null,
});

const moveItem = (list, id, toPosition) => {
    if (!list || list.size === 0) return list;

    const fromPosition = list.findIndex((page) => page.get('id') === id);
    const sourceItem = list.get(fromPosition);
    return list.delete(fromPosition).insert(toPosition, sourceItem);
};

const favoritePagesReducer = (state = initialState, action) => {
    switch (action.type) {
    case favoritePagesTypes.FAVORITE_PAGES_REQUEST:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case favoritePagesTypes.FAVORITE_PAGES_SUCCESS:
        return state.merge({
            loaded: status.COMPLETE,
            favoritePages: action.payload.favorite_pages,
            page: action.payload.page,
            total: action.payload.total,
            error: null,
        });
    case favoritePagesTypes.FAVORITE_PAGES_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            favoritePages: [],
            page: 0,
            total: 0,
            error: action.error,
        });
    case favoritePagesTypes.FAVORITE_PAGES_UPDATE_POSITION:
        return state.merge({
            favoritePages: moveItem(state.get('favoritePages'), action.payload.id, action.payload.position),
        });
    default:
        return state;
    }
};

export {
    favoritePagesActions,
    favoritePagesTypes,
    favoritePagesReducer,
};
