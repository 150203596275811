import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TRAININGSFILTER'),
    TRAININGSFILTER_GET: 'TRAININGSFILTER_GET',
    TRAININGSFILTER_SET: 'TRAININGSFILTER_SET',
    TRAININGSFILTER_SUCCESS: 'TRAININGSFILTER_SUCCESS',
};

export const actions = {
    trainingFilterSet: (obj) => utils.action(types.TRAININGSFILTER_SET, obj),
};

const initialState = fromJS([]);

const addAllClicked = (state, payload = '') => {
    const newState = state;
    const category = payload.category;
    let data = payload.data;

    if (typeof data === 'string') {
        data = data.replace(/<(?:.|\n)*?>/gm, '');
    }

    if (newState[category]) {
        for (let i = 0; i < newState[category].length; i++) {
            if (newState[category][i] === data) {
                newState[category].splice((i), 1);
                return newState;
            }
        }
    } else {
        newState[category] = [];
    }

    if (category === 'startDate' || category === 'endDate') {
        newState[category] = data;
    } else {
        newState[category].push(data);
    }

    return newState;
};

const constructQuery = ({
    product,
    systems,
    types,
    platform,
    page,
    startDate,
    endDate,
}) => {
    let query = '?';
    const queryList = [product, systems, types, platform, page, startDate, endDate];
    const queryName = ['product', 'systems', 'types', 'platforms', 'page', 'startDate', 'endDate'];
    let defindeCounter = 0;

    for (let i = 0; i < queryList.length; i++) {
        if (queryList[i]) {
            if (defindeCounter > 0) {
                query += '&';
            }

            query += queryName[i] + '=';
            if (queryName[i] === 'startDate') {
                const date = startDate._d;
                let month = date.getMonth();
                const day = date.getDate();
                const year = date.getFullYear();

                query += `${month++}/${day}/${year}`;
                query += ',';
            } else if (queryName[i] === 'endDate') {
                const date = endDate._d;
                let month = date.getMonth();
                const day = date.getDate();
                const year = date.getFullYear();

                query += `${month++}/${day}/${year}`;
            } else {
                for (let j = 0; j < queryList[i].length; j++) {
                    query += encodeURIComponent(queryList[i][j]);
                    // eslint-disable-next-line max-depth
                    if (queryList[i].length !== (j + 1)) {
                        query += ',';
                    }
                }
            }

            defindeCounter++;
        }
    }

    return query;
};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAININGSFILTER_SET:

        if (action.payload.data.category === 'page') {
            if (state.getIn([0])) {
                state = state.getIn([0, 'selected']).toJS();
                state.page = action.payload.data.data;
            } else {
                state = state.toJS();
                state.page = action.payload.data.data;
            }
        } else if (state.getIn([0])) {
            state = addAllClicked(state.getIn([0, 'selected']).toJS(), action.payload.data);
        } else {
            state = addAllClicked(state.toJS(), action.payload.data);
        }

        return fromJS({
            startDate: state.startDate,
            endDate: state.endDate,
            product: state.product,
            systems: state.systems,
            types: state.types,
            platform: state.platform,
            query: constructQuery(state),
            page: state.page,
        });

    case types.TRAININGSFILTER_GET:
        return state;

    default:
        return state;
    }
};

export const getTrainingFilter = (state) => state.get('app').get('trainingsFilter');

export const selectors = {
    getTrainingFilter: createSelector([getTrainingFilter], (trainingsFilter) => trainingsFilter),
};
