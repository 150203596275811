import { all, put, takeLatest } from 'redux-saga/effects';

import { types as fieldManagementTypes } from '@src/ducks/fieldManagement';

export function * watchFieldManagement() {
    yield takeLatest(fieldManagementTypes.FIELD_MANAGEMENT_GET, onFieldManagementGet);
    yield takeLatest(fieldManagementTypes.FIELD_MANAGEMENT_SUCCESS, onFieldManagementGetResponse);
}

export function * onFieldManagementGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onFieldManagementGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'field_management') {
        return yield all([
            put({
                type: fieldManagementTypes.FIELD_MANAGEMENT_SET,
                payload: response,
            }),
        ]);
    }
}
