import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('QUERYTIMESTAMPS'),
    QUERYTIMESTAMPS_GET: 'QUERYTIMESTAMPS_GET',
    QUERYTIMESTAMPS_SET: 'QUERYTIMESTAMPS_SET',
    QUERYTIMESTAMPS_SUCCESS: 'QUERYTIMESTAMPS_SUCCESS',
};

export const actions = {
    queryTimeStampsSet: (obj) => utils.action(types.QUERYTIMESTAMPS_SET, obj),
};

const initialState = fromJS({
    startTime: '',
    endTime: '',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.QUERYTIMESTAMPS_SET:
        return fromJS({
            startTime: action.payload.data.startTime,
            endTime: action.payload.data.endTime,
        });

    case types.QUERYTIMESTAMPS_GET:
        return state;

    default:
        return state;
    }
};

export const getQueryTimeStamps = (state) => state.get('app').get('queryTimeStamps');

export const selectors = {
    getQueryTimeStamps: createSelector([getQueryTimeStamps], (queryTimeStamps) => queryTimeStamps),
};
