import { fromJS } from 'immutable';

import { status } from '@src/global/status';

import * as utils from '../utils/duckHelpers';

const simplePagesTypes = utils.requestTypes('SIMPLE_PAGES');

const initialState = fromJS({
    loaded: status.LOADING,
    pages: [],
});

const simplePagesPagesReducer = (state = initialState, action) => {
    switch (action.type) {
    case simplePagesTypes.SIMPLE_PAGES_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case simplePagesTypes.SIMPLE_PAGES_SET:
        return state.merge({
            loaded: status.COMPLETE,
            pages: action.payload.simple_pages,
            error: null,
        });
    case simplePagesTypes.SIMPLE_PAGES_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            pages: [],
            error: action.error,
        });
    case simplePagesTypes.SIMPLE_PAGES_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    simplePagesPagesReducer as default,
    simplePagesTypes,
};
