import { call, put, takeEvery } from 'redux-saga/effects';

import { types as appTypes } from '@src/ducks/app';
import { types as errorType } from '@src/ducks/error';
import { apiGet } from '@src/services/api';
import { errorHandler } from '@src/utils/errorHelpers';
import { sagaResponse, sagaAction, sagaFailure } from '@src/utils/sagaHelpers';

export function * watchApp() {
    yield takeEvery(appTypes.APP_REQUEST, onRequest);
}

function * onRequest({ payload, fetch, notification, tracking, redirect }) {
    try {
        // Run pre-request saga functions here
        const action = yield put(sagaAction(payload, { payload }));

        // Remove any empty/undefined payloads
        let response;
        if (action.payload.route === '') {
            response = {};
        } else {
            response = yield call(apiGet, action.payload.route);
        }

        // Run the response saga functions hers
        response = yield put(sagaResponse(action.payload, { response, payload: action.payload }));

        return yield call(onSuccess, { response, fetch, notification, tracking, redirect });
    } catch (error) {
        return yield call(onFailure, { payload, error, fetch, redirect });
    }
}

export function * onSuccess({ response, fetch }) {
    yield put({
        type: appTypes.APP_SUCCESS,
        payload: response,
        fetch,
    });

    return response;
}

export function * onFailure({ payload, error, fetch }) {
    /* eslint-disable no-console */
    console.log(payload);
    console.log('*********APP ERROR********');
    console.error(error);
    console.error(error.message);
    console.log('****************************');
    /* eslint-enable no-console */

    if (error.response) {
        /* === inactivity === */
        if (error.response.status === 401) {
            // eslint-disable-next-line no-console
            console.log(error.response);
            yield put({
                type: errorType.ERROR_401,
                payload: error,
                fetch,
            });
        }

        /* === unauthorized  === */
        if (error.response.status === 403) {
            yield put({
                type: errorType.ERROR_403,
                payload: error,
                fetch,
            });
        }

        /* === network error  === */
        if (error.response.status === 500) {
            yield put({
                type: errorType.ERROR_500,
                payload: error,
                fetch,
            });
        }
    }

    if (error.message === 'Network Error') {
        yield put({
            type: errorType.ERROR_500,
            payload: error,
            fetch,
        });
    }

    yield put({
        type: appTypes.APP_FAILURE,
        payload: errorHandler(error),
        fetch,
    });

    // Run the response saga functions hers
    yield put(sagaFailure(payload, { error, payload }));

    return yield error;
}
