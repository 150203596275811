import SetRoute from '../services/setRoute';

export const fetchAlertsIndex = (
    fetch,
    param = '',
    page = '',
) => (
    {
        payload: {
            dataset: 'alerts',
            action: 'index',
            type:	`${param}`,
            route: SetRoute + `/api/v1/alerts?category=${param}${page}`,
            data: {},
        },
        fetch,
    }
);

export default fetchAlertsIndex;
