import SetRoute from '../services/setRoute';

export const fetchStates = (fetch) => (
    {
        payload: {
            dataset: 'states',
            action: 'get',
            route: SetRoute + '/api/v1/states',
            data: {},
        },
        fetch,
    }
);

export default fetchStates;
