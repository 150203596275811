import { all, put, takeLatest } from 'redux-saga/effects';

import { staticHomeTypes } from '@src/ducks/staticHome';

export function * watchStaticHome() {
    yield takeLatest(staticHomeTypes.STATIC_HOME_RESET, onReset);
    yield takeLatest(staticHomeTypes.STATIC_HOME_GET, onGet);
    yield takeLatest(staticHomeTypes.STATIC_HOME_SUCCESS, onGetResponse);
}

function * onReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'static_home') {
        return yield all([
            put({
                type: staticHomeTypes.STATIC_HOME_SET,
                payload: response,
            }),
        ]);
    }
}
