import { all, put, takeLatest } from 'redux-saga/effects';

import { types as rsmTypes } from '../ducks/rsm';

export function * watchRsm() {
    yield takeLatest(rsmTypes.RSM_GET, onRsmGet);
    yield takeLatest(rsmTypes.RSM_SUCCESS, onRsmGetResponse);
}

export function * onRsmGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onRsmGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'rsm') {
        if (!response.data) {
            response.data = payload.data;
        }

        return yield all([
            put({
                type: rsmTypes.RSM_GET,
                payload: response,
            }),
        ]);
    }
}
