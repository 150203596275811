import { all, put, takeLatest } from 'redux-saga/effects';

import { types as dateRangeTypes } from '../ducks/dateRange';

export function * watchDateRange() {
    yield takeLatest(dateRangeTypes.DATERANGE_RESET, onDateRangeReset);
    yield takeLatest(dateRangeTypes.DATERANGE_GET, onDateRangeGet);
    yield takeLatest(dateRangeTypes.DATERANGE_SUCCESS, onDateRangeGetResponse);
}

export function * onDateRangeReset({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onDateRangeGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onDateRangeGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'dateRange') {
        if (!Object.keys(response).length) {
            response = payload.data;
        }

        return yield all([
            put({
                type: dateRangeTypes.DATERANGE_SET,
                payload: response,
            }),
        ]);
    }
}
