import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const types = utils.requestTypes('PRODUCT');

const initialState = fromJS({
    loaded: 'loading',
    product: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.PRODUCT_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.PRODUCT_SET: {
        let content = action.payload.product;
        if (typeof content === 'string') {
            content = JSON.parse(content);
        }

        return state.merge({
            loaded: status.COMPLETE,
            product: content,
            error: null,
        });
    }

    case types.PRODUCT_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            product: {},
            error: action.error,
        });
    case types.PRODUCT_RESET:
        return initialState;
    default:
        return state;
    }
};

const getProduct = (state) => state.getIn(['app', 'product']);

export const selectors = {
    getProduct: createSelector([getProduct], (product) => product),
};
