import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('ALERTARTICLEPAGE'),
    ALERTARTICLEPAGE_GET: 'ALERTARTICLEPAGE_GET',
    ALERTARTICLEPAGE_SET: 'ALERTARTICLEPAGE_SET',
    ALERTARTICLEPAGE_SUCCESS: 'ALERTARTICLEPAGE_SUCCESS',
};

export const actions = {
    alertArticlePageSet: (obj) => utils.action(types.ALERTARTICLEPAGE_SET, obj),
};

const initialState = fromJS([]);

/* eslint-disable no-case-declarations */

export default (state = initialState, action) => {
    switch (action.type) {
    case types.ALERTARTICLEPAGE_SET:

        const data = action.payload.alert;
        return fromJS([
            {
                title: data.title,
                date: data.effective_until,
                product: data.products,
                channel: data.channel,
                readTime: data.readTime,
                agents: data.agents,
                states: data.states,
                content: data.contents[0].content,
                course: data.category,
                type: data.category,
                pageLoaded: true,
            },
        ]);

    case types.ALERTARTICLEPAGE_GET:
        return state;

    default:
        return state;
    }
};

export const getAlertArticlePage = (state) => state.get('app').get('alertArticlePage');

export const selectors = {
    getAlertArticlePage: createSelector([getAlertArticlePage], (alertArticlePage) => alertArticlePage),
};
