import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import Error401 from '@src/containers/errors/401error';
import Error403 from '@src/containers/errors/403error';
import Error404 from '@src/containers/errors/404error';
import Error500 from '@src/containers/errors/500error';
import { currentJwt } from '@src/utils/jwtHelpers';

import LoadingElement from '@src/components/loader';

export class GetSignedUrlFile extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string.isRequired,
        }),
    };

    constructor(props) {
        super(props);

        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            file: this.getJsonFromUrl(props.location.search) || {},
            error: null,
            success: false,
        };
    }

    getJsonFromUrl(searchParams) {
        const result = {};
        searchParams.substr(1).split('&').forEach((part) => {
            const item = part.split('=');
            result[item[0]] = decodeURIComponent(item[1]);
        });

        return result;
    }

    handleSuccess(signedHref) {
        location.href = signedHref;
        this.setState({
            success: true,
        });
    }

    handleError(error) {
        this.setState({
            error: error.response,
        });
    }

    getPreSignedUrl(id) {
        const url = `${process.env.API_HOST_URL}/api/v1/attachments/${id}/download`;
        const token = currentJwt();
        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },

        };

        return new Promise((resolve, reject) => {
            axios.get(url, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    componentDidMount() {
        const self = this;
        this.getPreSignedUrl(this.state.file.id)
            .then((success) => {
                self.handleSuccess(success.data.attachment.download_url);
            }).catch((error) => {
                self.handleError(error);
            });

        return null;
    }

    renderError() {
        switch (this.state.error.status) {
        case 401:
            return <Error401 />;
        case 403:
            return <Error403 />;
        case 404:
            return <Error404 type="file" />;
        default:
            return <Error500 />;
        }
    }

    renderSuccess(name) {
        return (
            <>
                <h2>Success!</h2>
                <p><strong>{name}</strong> has been downloaded.</p>
                <p>Please check for the file in your default download folder.</p>
            </>
        );
    }

    render() {
        const { file: { name } } = this.state;
        const { error, success } = this.state;

        return (
            <Col xs={12} className="post_page content-container content-view-container">
                <Card>
                    <Card.Body>
                        {
                            !success && !error
                            && <LoadingElement />
                        }
                        {
                            (success || error)
                            && <h1>Secure file download</h1>
                        }
                        {
                            !success && error
                            && this.renderError()
                        }
                        {
                            success
                            && this.renderSuccess(name)
                        }
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}
