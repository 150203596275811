import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('APPHISTORY'),
    APPHISTORY_GET: 'APPHISTORY_GET',
    APPHISTORY_SET: 'APPHISTORY_SET',
    APPHISTORY_SUCCESS: 'APPHISTORY_SUCCESS',
};

export const actions = {
    appHistorySet: (obj) => utils.action(types.APPHISTORY_SET, obj),
};

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.APPHISTORY_SET:
        if (!action || !action.payload || !action.payload.data) return state;

        return action.payload.data;

    case types.APPHISTORY_GET:
        return state;

    default:
        return state;
    }
};

export const getHistory = (state) => state.get('app').get('appHistory');
