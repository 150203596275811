import { put, takeLatest } from 'redux-saga/effects';

import { categoriesTypes } from '@src/ducks/categories';

export function * watchCategories() {
    yield takeLatest(categoriesTypes.CATEGORIES_GET, onGet);
    yield takeLatest(categoriesTypes.CATEGORIES_SUCCESS, onCategoriesGetResponse);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onCategoriesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'categories') {
        return yield put({
            type: categoriesTypes.CATEGORIES_SET,
            payload: response,
        });
    }
}
