import { all, put, takeLatest } from 'redux-saga/effects';

import { types as salesPromotionTypes } from '../ducks/sales_promotion';

export function * watchSalesPromotion() {
    yield takeLatest(salesPromotionTypes.SALES_PROMOTION_GET, onSalesPromotionGet);
    yield takeLatest(salesPromotionTypes.SALES_PROMOTION_SUCCESS, onSalesPromotionGetResponse);
}

function * onSalesPromotionGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onSalesPromotionGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'sales_promotion') {
        return yield all([
            put({
                type: salesPromotionTypes.SALES_PROMOTION_GET,
                payload: response,
            }),
        ]);
    }
}
