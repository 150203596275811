import { fromJS } from 'immutable';

import { requestTypes } from '@src/utils/duckHelpers';

const topMenuTypes = requestTypes('TOP_MENU');

const initialState = fromJS({
    loaded: 'loading',
    menu: [],
});

const topMenuReducer = (state = initialState, action) => {
    switch (action.type) {
    case topMenuTypes.TOP_MENU_REQUEST:
        return state.merge({
            loaded: 'loading',
        });
    case topMenuTypes.TOP_MENU_SET:
        return state.merge({
            loaded: 'complete',
            menu: action.payload.menus || [],
        });
    default:
        return state;
    }
};

export {
    topMenuReducer as default,
    topMenuTypes,
};
