import { fromJS } from 'immutable';

import { requestTypes } from '@src/utils/duckHelpers';

const bottomMenuTypes = requestTypes('BOTTOM_MENU');

const initialState = fromJS({
    loaded: 'loading',
    menu: [],
});

const bottomMenuReducer = (state = initialState, action) => {
    switch (action.type) {
    case bottomMenuTypes.BOTTOM_MENU_REQUEST:
        return state.merge({
            loaded: 'loading',
        });
    case bottomMenuTypes.BOTTOM_MENU_SET:
        return state.merge({
            loaded: 'complete',
            menu: action.payload.menus || [],
        });
    default:
        return state;
    }
};

export {
    bottomMenuReducer as default,
    bottomMenuTypes,
};
