// Startup point for client side application
import React from 'react';
import { render } from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
    BrowserRouter,
    Switch,
} from 'react-router-dom';

import IEErrorPage from '@src/containers/errors/ie-error-page';
import { isIE } from '@src/utils/browser-detect';

import RouteWithSubRoutes from '@src/components/RoutesWithSubRoutes';

import { routes } from './routes';
import store from './store';

if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [new BrowserTracing()],

        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
        tracesSampleRate: 0.3,
    });
}

if (isIE) {
    render(
        <IEErrorPage />,
        document.querySelector('#root'),
    );
} else {
    const queryCache = new QueryCache({
        defaultConfig: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
            },
        },
    });

    const s = store();

    render(
        <ReactQueryCacheProvider queryCache={queryCache}>
            <Provider store={s}>
                <BrowserRouter>
                    <Switch>
                        {
                            routes.map((route) => (
                                <RouteWithSubRoutes key={route.path} {...route} />
                            ))
                        }
                    </Switch>
                </BrowserRouter>
            </Provider>
        </ReactQueryCacheProvider>
        ,
        document.querySelector('#root'),
    );
}
