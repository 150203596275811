import Immutable from 'immutable';

export function initialState(data) {
    return Immutable.fromJS(data);
}

export function action(type, payload = {}) {
    return { type, ...payload };
}

const REQUEST_TYPES = [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
    'SET',
    'GET',
    'RESET',
];

export const requestTypes = (base) => (
    REQUEST_TYPES.reduce((action, type) => {
        const baseType = `${base}_${type}`;

        action[baseType] = baseType;

        return action;
    }, {})
);

export function isManager(accessLevel) {
    return Boolean(accessLevel === 'admin' || accessLevel === 'home_office_employee' || accessLevel === 'manager');
}

