import { all, put, takeLatest } from 'redux-saga/effects';

import { types } from '../ducks/topMenuClicked';

export function * watchTopMenuClicked() {
    yield takeLatest(types.TOPMENUCLICKED_GET, onTopMenuClickedSet);
    yield takeLatest(types.TOPMENUCLICKED_SUCCESS, onTopMenuClickedResponse);
}

export function * onTopMenuClickedSet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTopMenuClickedResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'topMenuClicked') {
        return yield all([
            put({
                type: types.TOPMENUCLICKED_SET,
                payload: response,
            }),
        ]);
    }
}
