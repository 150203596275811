import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('SAVEDMEGAQUERY'),
    SAVEDMEGAQUERY_GET: 'SAVEDMEGAQUERY_GET',
    SAVEDMEGAQUERY_SET: 'SAVEDMEGAQUERY_SET',
    SAVEDMEGAQUERY_SUCCESS: 'SAVEDMEGAQUERY_SUCCESS',
};

export const actions = {
    savedMegaQuerySet: (obj) => utils.action(types.SAVEDMEGAQUERY_SET, obj),
};

const initialState = fromJS({
    savedMegaQuery: [],
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.SAVEDMEGAQUERY_SET:
        return fromJS({
            savedMegaQuery: action.payload.data,
        });

    case types.SAVEDMEGAQUERY_GET:
        return state;

    default:
        return state;
    }
};

export const getSavedMegaQuery = (state) => state.get('app').get('savedMegaQuery');

export const selectors = {
    getSavedMegaQuery: createSelector([getSavedMegaQuery], (savedMegaQuery) => savedMegaQuery),
};
