import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import { fixEditable } from '@src/services';
import * as utils from '@src/utils/duckHelpers';

export const types = utils.requestTypes('FIELD_MANAGEMENT');

const initialState = fromJS({
    loaded: 'loading',
    fieldManagement: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.FIELD_MANAGEMENT_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.FIELD_MANAGEMENT_SET:
        return fromJS({
            loaded: status.COMPLETE,
            fieldManagement: fixEditable(action.payload.field_management_news || {}),
            error: null,
        });
    case types.FIELD_MANAGEMENT_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            fieldManagement: {},
            error: action.error,
        });
    default:
        return state;
    }
};

export const getFieldManagement = (state) => state.getIn(['app', 'fieldManagement']);

export const selectors = {
    getFieldManagement: createSelector([getFieldManagement], (fieldManagement) => fieldManagement),
};
