import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('DATERANGE'),
    DATERANGE_RESET: 'DATERANGE_RESET',
    DATERANGE_SET: 'DATERANGE_SET',
    DATERANGE_GET: 'DATERANGE_GET',
    DATERANGE_SUCCESS: 'DATERANGE_SUCCESS',
};

export const actions = {
    dateRangeSet: (obj) => utils.action(types.DATERANGE_SET, obj),
};

const initialState = fromJS({
    dateRange: [],
    pageLoaded: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.DATERANGE_SET:
        if (action.payload.taxonomy) {
            return fromJS({
                dateRange: action.payload.taxonomy,
                pageLoaded: true,
            });
        }

        break;
    case types.DATERANGE_RESET:
        return initialState;

    default:
        return state;
    }
};

const getDateRange = (state) => state.get('app').get('dateRange');

export const selectors = {
    getDateRange: createSelector([getDateRange], (dateRange) => dateRange),
};
