import { all, put, takeLatest } from 'redux-saga/effects';

import { staticCohortsTypes } from '@src/ducks/staticCohorts';

function * watchStaticCohorts() {
    yield takeLatest(staticCohortsTypes.STATIC_COHORTS_GET, onGet);
    yield takeLatest(staticCohortsTypes.STATIC_COHORTS_SUCCESS, onGetResponse);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'static_cohorts') {
        return yield all([
            put({
                type: staticCohortsTypes.STATIC_COHORTS_SET,
                payload: response,
            }),
        ]);
    }
}

export { watchStaticCohorts };
