import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    historyActions,
    storeActions,
    dynAct,
    getHistory,
} from '@src/ducks';
import {
    setAppHistory,
} from '@src/requests';
import {
    ForceAppScrollTopToZero,
} from '@src/services';
import { unique } from '@src/utils/componentHelpers';

import ContactUsLink from '@src/components/ContactUsLink';

import bootstrap from '@src/css/bootstrap.scss';
import '@src/css/metlife_css/buttons.scss';

import styles from './error.scss';

const mapStateToProps = (state) => ({
    appHistory: getHistory(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        ...dynAct,
        ...storeActions,
        ...historyActions,
    }, dispatch),
});

/**
 * Error403 - 403 error page
 * @param {object} actions Redux Actions
 * @param {object} history App History
 */

class Error403 extends Component {
    constructor(props) {
        super(props);
        this.fetch = unique();
    }

    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        ForceAppScrollTopToZero();
        this.props.actions.appRequest(setAppHistory(this.props.history));
    }

    render() {
        return (
            <div id="home" className={`col-12 ${styles.error_page}`}>
                <div className={bootstrap.container}>
                    <div className={bootstrap.row}>
                        <div className={`${bootstrap.col} ${bootstrap['text-center']}`}>
                            <div className="error-container">
                                <img src="/img/Computer-Lock-Icon.png" alt="Computer screen with a Lock Pad on the screen" />
                                <h1>We&#39;re Sorry</h1>
                                <h2>You are currently not authorized to view this content.</h2>
                                <p>Please, contact your manager or supervisor for more information.</p>
                                <p>For more support please <ContactUsLink>contact us</ContactUsLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error403);
