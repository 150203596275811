import SetRoute from '@src/services/setRoute';

const fetchProducts = (fetch, params = '') => ({
    payload: {
        dataset: 'products',
        action: 'get',
        route: SetRoute + `/api/v1/products${params}`,
        data: {},
    },
    fetch,
});

const fetchAllProducts = (fetch, params = '') => ({
    payload: {
        dataset: 'all_products',
        action: 'get',
        route: SetRoute + `/api/v1/products/all${params}`,
        data: {},
    },
    fetch,
});

const fetchDropdownProducts = (fetch, params = '') => ({
    payload: {
        dataset: 'dropdown_products',
        action: 'get',
        route: SetRoute + `/api/v1/products${params}`,
        data: {},
    },
    fetch,
});

export {
    fetchProducts,
    fetchAllProducts,
    fetchDropdownProducts,
};
