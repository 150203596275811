import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    GUIDES_SELECTIONS: 'GUIDES_SELECTIONS',
};

export const actions = {
    setGuidesSelections: (obj) => utils.action(types.GUIDES_SELECTIONS, obj),
};

const initialState = fromJS({
    data: {
        currentPage: 1,
        dropdownResourceData: [],
        dropdownResourceDataLoaded: false,
        dropdownStateData: [],
        dropdownStateDataLoaded: false,
        loaded: false,
        searchString: '',
        selectedResource: 'all-options',
        selectedResourceType: '',
        selectedState: 'all-options',
        selectedStateType: '',
    },
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.GUIDES_SELECTIONS:
        if (action.payload) {
            return fromJS({
                data: action.payload,
            });
        }

        return state;
    default:
        return state;
    }
};

const getGuidesSelections = (state) => state.get('app').get('guidesSelections');

export const selectors = {
    getGuidesSelections: createSelector([getGuidesSelections], (data) => data),
};
