import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    JOB_AIDS_SELECTIONS: 'JOB_AIDS_SELECTIONS',
};

export const actions = {
    setJobAidsSelections: (obj) => utils.action(types.JOB_AIDS_SELECTIONS, obj),
};

const initialState = fromJS({
    data: {
        currentPage: 1,
        megaMenuData: [],
        megaMenuDataLoaded: false,
        loaded: false,
        searchString: '',
        selectedOptions: [],
    },
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.JOB_AIDS_SELECTIONS:
        if (action.payload) {
            return fromJS({
                data: action.payload,
            });
        }

        break;
    default:
        return state;
    }
};

const getJobAidsSelections = (state) => state.get('app').get('jobAidsSelections');

export const selectors = {
    getJobAidsSelections: createSelector([getJobAidsSelections], (data) => data),
};
