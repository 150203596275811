import axios from 'axios';

import { setRoute } from '@src/services';
import { currentJwt } from '@src/utils/jwtHelpers';

const REFRESH_AUTH_TOKEN_PATH = '/api/v1/auth_token/refresh';

export const refreshAuthToken = () => {
    const token = currentJwt();
    return axios({
        method: 'post',
        url: `${setRoute()}${REFRESH_AUTH_TOKEN_PATH}`,
        data: {},
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }).then((res) => {
        if (!res || res.status !== 200) {
            throw new Error('Invalid session');
        }

        return res.data;
    }).catch((_error) => {
        throw new Error('Invalid session');
    });
};
