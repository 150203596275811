import SetRoute from '../services/setRoute';

export const fetchProduct = (fetch, params) => ({
    payload: {
        dataset: 'product',
        action: 'get',
        route: SetRoute + `/api/v1/products${params}`,
        data: {},
    },
    fetch,
});

export default fetchProduct;
