import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const types = {
    ...utils.requestTypes('ALERT'),
    ALERT_MARK_AS_READ: 'ALERT_MARK_AS_READ',
    ALERT_MARK_AS_READ_SUCCESS: 'ALERT_MARK_AS_READ_SUCCESS',
    ALERT_MARK_AS_READ_FAILURE: 'ALERT_MARK_AS_READ_FAILURE',
};

export const actions = {
    alertSet: (obj) => utils.action(types.ALERT_SET, obj),
    updateMarkAsRead: (id) => utils.action(types.ALERT_MARK_AS_READ, { payload: { id } }),
    updateMarkAsReadSuccess: (response) => utils.action(types.ALERT_MARK_AS_READ_SUCCESS, { payload: response }),
    updateMarkAsReadFailure: (error) => utils.action(types.ALERT_MARK_AS_READ_FAILURE, { error }),
};

const initialState = fromJS({
    loaded: 'loading',
    alert: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.ALERT_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case types.ALERT_SET:
        return state.merge({
            loaded: status.COMPLETE,
            alert: action.payload.alert || {},
        });
    case types.ALERT_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            alert: {},
            error: action.error,
        });
    case types.ALERT_MARK_AS_READ:
        return state.merge({
            alert: action.payload.alert,
        });
    case types.ALERT_MARK_AS_READ_SUCCESS:
        return state.merge({
            alert: action.payload.data.alert,
        });
    case types.ALERT_RESET:
        return initialState;
    default:
        return state;
    }
};

const getAlert = (state) => state.getIn(['app', 'alert']);

export const selectors = {
    getAlert: createSelector([getAlert], (alert) => alert),
};
