import { fromJS } from 'immutable';
import identity from 'lodash/identity';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('JOB_AIDS'),
    JOB_AIDS_SET: 'JOB_AIDS_SET',
    JOB_AIDS_GET: 'JOB_AIDS_GET',
    JOB_AIDS_SUCCESS: 'JOB_AIDS_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    job_aidsSet: (obj) => utils.action(types.JOB_AIDS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    // eslint-disable-next-line camelcase
    job_aids: [],
    total: 0,
    page: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.JOB_AIDS_GET:
        return fromJS({
            ...state,
            loaded: 'loading',
        });
    case types.JOB_AIDS_SET:
        if (action.payload.job_aids) {
            if (action.payload.job_aids.length > 0) {
                return fromJS({
                    loaded: 'complete',
                    // eslint-disable-next-line camelcase
                    job_aids: action.payload.job_aids,
                    total: action.payload.total,
                    page: action.payload.page,
                });
            }
        }

        return fromJS({
            loaded: 'empty',
            // eslint-disable-next-line camelcase
            job_aids: [],
            total: 0,
            page: 0,
        });
    case types.JOB_AIDS_RESET:
        return initialState;
    default:
        return state;
    }
};

const getJobAids = (state) => state.get('app').get('job_aids');

export const selectors = {
    getJobAids: createSelector([getJobAids], identity),
};
