import React from 'react';

import '../app.scss';

const style = {
    'text-align': 'center',
    'padding-top': '180px',
};

const IEErrorPage = () => {
    return (
        <div className="error-container" style={style}>
            <img src="/img/browser_icon.png" alt="Browser" />
            <h2>Internet Explorer Error</h2>
            <h5>The Producer News Portal is not compatible with Internet Explorer.</h5>
            <p>
                Please try to access this page using
                { ' ' }
                <a
                    href="https://www.google.com/chrome/"
                    className="ml-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Chrome
                </a>,
                { ' ' }
                <a
                    href="https://www.microsoft.com/edge"
                    className="ml-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Microsoft Edge
                </a>,
                or another modern browser.
            </p>
        </div>
    );
};

export default IEErrorPage;
