import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TRAININGPAGELOADED'),
    TRAININGPAGELOADED_GET: 'TRAININGPAGELOADED_GET',
    TRAININGPAGELOADED_SET: 'TRAININGPAGELOADED_SET',
    TRAININGPAGELOADED_SUCCESS: 'TRAININGPAGELOADED_SUCCESS',
};

export const actions = {
    TrainingPageLoadedSet: (obj) => utils.action(types.TRAININGPAGELOADED_SET, obj),
};

const initialState = {};

/* eslint-disable no-case-declarations */
export default (state = initialState, action) => {
    switch (action.type) {
    case types.TRAININGPAGELOADED_SET:
        const payload = action.payload.data;

        state[payload] = true;

        return state;

    case types.TRAININGPAGELOADED_GET:
        return state;

    default:
        return state;
    }
};

const getTrainingPageLoaded = (state) => state.get('app').get('trainingPageLoaded');

export const selectors = {
    getTrainingPageLoaded: createSelector([getTrainingPageLoaded], (trainingPageLoaded) => trainingPageLoaded),
};
