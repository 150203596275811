export const fixEditable = (data) => {
    if (data) {
        if (data.contents) {
            if (data.contents[0]) {
                if (data.contents[0].content) {
                    data.contents[0].content = data.contents[0].content.replace(/contenteditable/g, 'data-unusable-field');
                }
            }
        }
    }

    return data;
};
