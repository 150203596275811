import { fromJS } from 'immutable';

import { status } from '@src/global/status';

import * as utils from '../utils/duckHelpers';

const simplePageTypes = utils.requestTypes('SIMPLE_PAGE');

const initialState = fromJS({
    loaded: status.LOADING,
    page: {},
});

const simplePagesPageReducer = (state = initialState, action) => {
    switch (action.type) {
    case simplePageTypes.SIMPLE_PAGE_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case simplePageTypes.SIMPLE_PAGE_SET:
        return state.merge({
            loaded: status.COMPLETE,
            page: action.payload.simple_page,
            error: null,
        });
    case simplePageTypes.SIMPLE_PAGE_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            page: {},
            error: action.error,
        });
    case simplePageTypes.SIMPLE_PAGE_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    simplePagesPageReducer as default,
    simplePageTypes,
};
