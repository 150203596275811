import { all, put, takeLatest } from 'redux-saga/effects';

import { types as salesPromotionsTypes } from '../ducks/sales_promotions';

export function * watchSalesPromotions() {
    yield takeLatest(salesPromotionsTypes.SALES_PROMOTIONS_GET, onSalesPromotionsGet);
    yield takeLatest(salesPromotionsTypes.SALES_PROMOTIONS_SUCCESS, onSalesPromotionsGetResponse);
}

function * onSalesPromotionsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onSalesPromotionsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'sales_promotions') {
        return yield all([
            put({
                type: salesPromotionsTypes.SALES_PROMOTIONS_GET,
                payload: response,
            }),
        ]);
    }
}
