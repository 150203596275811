import { fromJS } from 'immutable';

import * as utils from '../utils/duckHelpers';

const staticCohortsTypes = {
    ...utils.requestTypes('STATIC_COHORTS'),
    STATIC_COHORTS_GET: 'STATIC_COHORTS_GET',
    STATIC_COHORTS_SET: 'STATIC_COHORTS_SET',
    STATIC_COHORTS_SUCCESS: 'STATIC_COHORTS_SUCCESS',
};

const initialState = fromJS({
    loaded: 'loading',
    data: null,
});

const staticCohortsReducer = (state = initialState, action) => {
    switch (action.type) {
    case staticCohortsTypes.STATIC_COHORTS_GET:
        return state.merge({
            loaded: 'loading',
        });
    case staticCohortsTypes.STATIC_COHORTS_SET:
        return state.merge({
            loaded: 'complete',
            data: action.payload.static,
        });
    case staticCohortsTypes.STATIC_COHORTS_RESET:
        return initialState;
    default:
        return state;
    }
};

export {
    staticCohortsReducer as default,
    staticCohortsTypes,
};
