import { all, put, takeLatest } from 'redux-saga/effects';

import { types as guidesTypes } from '@src/ducks/guides';
import { types as guidesByStateTypes } from '@src/ducks/guidesByState';

function * watchGuides() {
    yield takeLatest(guidesTypes.GUIDES_GET, onGuidesGet);
    yield takeLatest(guidesTypes.GUIDES_SUCCESS, onGuidesGetResponse);
}

function * onGuidesGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGuidesGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'guides') {
        return yield all([
            put({
                type: guidesTypes.GUIDES_SET,
                payload: response,
            }),
        ]);
    }
}

function * watchGuidesByState() {
    yield takeLatest(guidesByStateTypes.GUIDES_BY_STATE_GET, onGuidesByStateGet);
    yield takeLatest(guidesByStateTypes.GUIDES_BY_STATE_SUCCESS, onGuidesByStateGetResponse);
}

function * onGuidesByStateGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGuidesByStateGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'guides_by_state') {
        return yield all([
            put({
                type: guidesByStateTypes.GUIDES_BY_STATE_SET,
                payload: response,
            }),
        ]);
    }
}

export {
    watchGuides,
    watchGuidesByState,
};
