import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { apiPatch, buildApiURL } from '@src/services/api';
import { handleSagaFailure } from '@src/utils/sagaHelpers';

import { actions, types as alertTypes } from '../ducks/alert';

export function * watchAlert() {
    yield takeLatest(alertTypes.ALERT_GET, onAlertGet);
    yield takeLatest(alertTypes.ALERT_SUCCESS, onAlertGetResponse);
    yield takeEvery(alertTypes.ALERT_MARK_AS_READ, onMarkAsRead);
}

function * onAlertGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onAlertGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'alert') {
        return yield all([
            put({
                type: alertTypes.ALERT_SET,
                payload: response,
            }),
        ]);
    }
}

function * onMarkAsRead({ payload }) {
    try {
        const response = yield call(
            apiPatch,
            buildApiURL(`/api/v1/alerts/${payload.id}/mark_as_read`),
            {},
        );

        yield put(actions.updateMarkAsReadSuccess(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(actions.updateMarkAsReadFailure(error));
    }
}
