import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('UNCHECKED_BY_CATEGORY_COUNT'),
    UNCHECKED_BY_CATEGORY_COUNT_RESET: 'UNCHECKED_BY_CATEGORY_COUNT_RESET',
    UNCHECKED_BY_CATEGORY_COUNT_SET: 'UNCHECKED_BY_CATEGORY_COUNT_SET',
    UNCHECKED_BY_CATEGORY_COUNT_GET: 'UNCHECKED_BY_CATEGORY_COUNT_GET',
    UNCHECKED_BY_CATEGORY_COUNT_SUCCESS: 'UNCHECKED_BY_CATEGORY_COUNT_SUCCESS',
};

export const actions = {
    // eslint-disable-next-line camelcase
    unchecked_by_category_countSet: (obj) => utils.action(types.UNCHECKED_BY_CATEGORY_COUNT_SET, obj),
};

const initialState = fromJS({
    // eslint-disable-next-line camelcase
    unchecked_by_category_count: null,
    // eslint-disable-next-line camelcase
    disaster_recovery_count: null,
    // eslint-disable-next-line camelcase
    technology_count: null,
    // eslint-disable-next-line camelcase
    highlighted_count: null,
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.UNCHECKED_BY_CATEGORY_COUNT_SET:
        if (action.payload.alerts_by_category_count !== null) {
            const _state = state ? state.toJS() : [];

            return fromJS({
                // eslint-disable-next-line camelcase
                unchecked_by_category_count: _state.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                disaster_recovery_count: _state.disaster_recovery_count,
                // eslint-disable-next-line camelcase
                technology_count: _state.technology_count,
                // eslint-disable-next-line camelcase
                highlighted_count: _state.highlighted_count,
                loaded: 'complete',
            });
        }

        break;
    case 'disaster_recovery_count':
        if (action.payload.alerts_by_category_count !== null) {
            const _state = state ? state.toJS() : [];

            return fromJS({
                // eslint-disable-next-line camelcase
                unchecked_by_category_count: _state.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                disaster_recovery_count: action.payload.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                technology_count: _state.technology_count,
                // eslint-disable-next-line camelcase
                highlighted_count: _state.highlighted_count,
                loaded: 'complete',
            });
        }

        break;
    case 'technology_count':
        if (action.payload.alerts_by_category_count !== null) {
            const _state = state ? state.toJS() : [];
            return fromJS({
                // eslint-disable-next-line camelcase
                unchecked_by_category_count: _state.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                technology_count: action.payload.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                disaster_recovery_count: _state.disaster_recovery_count,
                // eslint-disable-next-line camelcase
                highlighted_count: _state.highlighted_count,
                loaded: 'complete',
            });
        }

        break;
    case 'highlighted_count': {
        if (action.payload.alerts_by_category_count !== null) {
            const _state = state ? state.toJS() : [];
            return fromJS({
                // eslint-disable-next-line camelcase
                unchecked_by_category_count: _state.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                highlighted_count: action.payload.alerts_by_category_count,
                // eslint-disable-next-line camelcase
                disaster_recovery_count: _state.disaster_recovery_count,
                // eslint-disable-next-line camelcase
                technology_count: _state.technology_count,
                loaded: 'complete',
            });
        }

        const _state = state ? state.toJS() : [];
        return fromJS({
            // eslint-disable-next-line camelcase
            unchecked_by_category_count: _state.alerts_by_category_count,
            // eslint-disable-next-line camelcase
            highlighted_count: _state.highlighted_count,
            // eslint-disable-next-line camelcase
            disaster_recovery_count: _state.disaster_recovery_count,
            // eslint-disable-next-line camelcase
            technology_count: _state.technology_count,
            loaded: _state.loaded ? 'complete' : 'empty',
        });
    }

    case types.UNCHECKED_BY_CATEGORY_COUNT_RESET:
        return initialState;

    default:
        return state;
    }
};

// eslint-disable-next-line camelcase
const getUnchecked_by_category_count = (state) => state.get('app').get('unchecked_by_category_count');

export const selectors = {
    // eslint-disable-next-line camelcase
    getUnchecked_by_category_count: createSelector([getUnchecked_by_category_count], (unchecked_by_category_count) => unchecked_by_category_count),
};
