import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('GUIDES'),
    GUIDES_SET: 'GUIDES_SET',
    GUIDES_GET: 'GUIDES_GET',
    GUIDES_SUCCESS: 'GUIDES_SUCCESS',
};

export const actions = {
    guidesSet: (obj) => utils.action(types.GUIDES_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    guides: [],
    page: 0,
    total: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.GUIDES_GET:
        return fromJS({
            ...state,
            loaded: 'loading',
        });
    case types.GUIDES_SET:
        if (action.payload.guides) {
            if (action.payload.guides.length > 0) {
                return fromJS({
                    loaded: 'complete',
                    guides: action.payload.guides,
                    page: action.payload.page,
                    total: action.payload.total,
                });
            }
        }

        return fromJS({
            ...state,
            loaded: 'empty',
            guides: [],
            page: 0,
            total: 0,
        });
    case types.GUIDES_RESET:
        return initialState;
    default:
        return state;
    }
};

const getGuides = (state) => state.get('app').get('guides');

export const selectors = {
    getGuides: createSelector([getGuides], (guides) => guides),
};
